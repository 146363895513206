export class KeyValue {
    public Key!: string;
    public Value!: string;
}

export class StringUpdater {
    public Id!: string;
    public Values!: KeyValue[];
    public AdditionalValues!: string;
    public AdditionalValues2!: string;
}

export class Int64Updater {
    public Id!: number;
    public Values!: KeyValue[];
    public AdditionalValues!: string;
    public AdditionalValues2!: string;
    public AdditionalValues3!: string;
}
