import { ZoneType } from './zone-type';

export class Zone {
    public ZoneTypeId!: number;
    public ZoneId!: number;
    public ZoneName!: string;
    public ZoneDesc!: string;
    public GpsLatitude!: number;
    public GpsLongitude!: number;
}

export class ZoneInfo
{
    public ZoneType: ZoneType = new ZoneType();
    public Zone: Zone = new Zone();
}
