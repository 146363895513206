import { ContactGroupService } from './../../services/contact-group/contact-group.service';
import { ZoneService } from './../../services/zone/zone.service';
import { ChannelDefService } from './../../services/channel-def/channel-def.service';
import { Int64Updater, KeyValue } from './../../Helpers/key-value';
import { ContactGroupDef } from './../../Models/contact-group-def';
import { GroupPerm } from './../../Models/group-perm';
import { GroupPermService } from './../../services/group-perm/group-perm.service';
import { ZoneType } from './../../Models/zone-type';
import { ContactLocation, ContactLocationData, ContactLocationInfo } from './../../Models/contact-location';
import { ContactChannel, ContactChannelData, ContactChannelInfo, ContactChannelInfoData } from './../../Models/contact-channel';
import { ContactLocationService } from './../../services/contact-location/contact-location.service';
import { ContactChannelService } from './../../services/contact-channel/contact-channel.service';
import { Contact, ContactInfo } from './../../Models/contact';
import { ContactService } from './../../services/contact/contact.service';
import { GroupDefService } from './../../services/group-def/group-def.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ContactGroupDefInfo } from 'src/app/Models/contact-group-def';
import { getgroups } from 'process';
import { NewContactInfo } from 'src/app/Models/contact';
import { DomSanitizer } from '@angular/platform-browser';
import { ContactChannelDef } from 'src/app/Models/contact-channel-def';
import { Zone } from 'src/app/Models/zone';
import { GroupService } from 'src/app/services/group/group.service';
import { ContactGroupData } from 'src/app/Models/contact-group';
import { Variables } from 'src/app/Helpers/variables';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// import * as CryptoJS from 'crypto-js';

const CryptoJS = require('crypto-js');

@Component({
  selector: 'app-contact-groups',
  templateUrl: './contact-groups.component.html',
  styleUrls: ['./contact-groups.component.css']
})
export class ContactGroupsComponent implements OnInit {

  groups: ContactGroupDefInfo[] = [];
  contacts: NewContactInfo[] = [];
  contactgroups: any[] = [];
  contacttypes: any[] = [];
  companies: any[] = [];
  zones: any[] = [];
  citems: MenuItem[] = [];

  channels: ContactChannelInfo[] = [];
  locations: ContactLocationInfo[] = [];

  readperms: GroupPerm[] = [];
  writeperms: GroupPerm[] = [];
  deleteperms: GroupPerm[] = [];

  usergroups: any[] = [];
  usergroupsperm: any[] = [];

  usergroupsdelete: any[] = [];
  usergroupsread: any[] = [];
  usergroupswrite: any[] = [];

  group: any;
  contact: any;

  loadmoreg = false;
  loadmorec = false;

  pageg = 1;
  pagec = 1;
  size = 100;

  isnogroups = false;

  isdelete = false;

  loading = false;

  divname = 'mydiv';

  filtervalue: any;
  isgroupdetail = false;
  iscontactdetail = false;
  iscontacts = false;

  editmode = false;

  items: MenuItem[] = [];

  addgroup = false;
  addcontact = false;
  editgroup = false;
  editcontact = false;
  addperm = false;
  showlocs = false;
  additem = false;
  edititem = false;
  additemedit = false;
  edititemedit = false;

  perm = 'none';

  groupnameerror = false;
  groupdescerror = false;
  usergrouperror = false;

  GroupName: string | undefined;
  GroupDesc: string | undefined;
  UserGroup: any | undefined;

  ContactGroup: any[] = [];
  ContactType: any;
  FirstName: string | undefined;
  LastName: string | undefined;
  ContactTitle: string | undefined;
  DoB: Date | undefined;
  Picture: string | undefined;
  CompanyName: string | undefined;
  Company: any;

  CompanyId: number | undefined;

  picturename: string | undefined;
  picturetype: string | undefined;
  pic: any;

  contactgrouperror = false;
  contacttypeerror = false;
  firstnameerror = false;
  lastnameerror = false;
  contacttitleerror = false;
  doberror = false;
  docerror = false;
  pictureerror = false;
  companynameerror = false;
  companyerror = false;
  locationerror = false;
  valueerror = false;

  singlescreate: any = [];
  multiplescreate: any = [];

  singlesedit: any = [];
  multiplesedit: any = [];

  locs: any[] = [];
  locnames: any[] = [];

  value: any = [];
  ItemValue!: string;
  modifyitemvalue!: string;

  modifyitemedit: any;

  addtitle: any;
  typeid: number | undefined;
  typetype: string | undefined;

  filterform: any;

  constructor(
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private groupDefService: GroupDefService,
    private contactService: ContactService,
    private domSanitizer: DomSanitizer,
    private contChanService: ContactChannelService,
    private contLocService: ContactLocationService,
    private groupPermService: GroupPermService,
    private messageService: MessageService,
    private groupService: GroupService,
    private channelDefService: ChannelDefService,
    private zoneService: ZoneService,
    private cgroupService: ContactGroupService,
    private fb: FormBuilder
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang: string | null;
    browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }

    this.translate.use(browserLang?.match(/English|French/) ? browserLang : 'English');

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.ADDGROUP').subscribe((resp2: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ADDCONTACT').subscribe((resp3: string) => {
        this.items.push({label: resp2, id: 'addgroup',
         command: async (event) => { await this.showAdd(event, 'group'); }});
        this.items.push({label: resp3, id: 'addcontact',
         command: async (event) => { await this.showAdd(event, 'contact'); }});
      });
    });

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.PERSON').subscribe((resp2: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.COMPANY').subscribe((resp3: string) => {
        this.contacttypes.push({label: resp2, id: 'person', value: 'Person' });
        this.contacttypes.push({label: resp3, id: 'company', value: 'Company' });
      });
    });
   }

  async ngOnInit(): Promise<any> {
    this.filterform = this.fb.group({
      filter: new FormControl()
    }) as FormGroup;

    await this.getGroups();
  }

  async showAdd(event: any, val: any): Promise<any> {
    if (val === 'group') {
      this.GroupName = '';
      this.GroupDesc = '';
      this.UserGroup = '';

      await this.getUserGroups();

      this.addgroup = true;
      this.addcontact = false;
    } else if (val === 'contact') {
      this.cancel();
      this.cancelItem();
      await this.getContactGroups();
      await this.getChannelDef();
      await this.getCompanies();

      this.addgroup = false;
      this.addcontact = true;
    }
  }

  async getGroups(): Promise<any> {
    this.loading = true;
    this.isnogroups = false;
    let value = ' ';

    // if (this.filtervalue !== undefined && this.filtervalue !== null && this.filtervalue.trim() !== '') {
    //   value = this.filtervalue;
    // }
    if (this.filterform !== undefined && this.filterform !== null && this.filterform.value !== undefined
      && this.filterform.value !== null && this.filterform.value.filter !== undefined
      && this.filterform.value.filter !== null && this.filterform.value.filter.trim() !== '') {
      value = this.filterform.value.filter;
    }

    const gps = await this.groupDefService.getGroups(this.pageg, this.size, 0, ' ', value).toPromise()
    .catch(err => this.handleError(err));

    if (gps !== undefined && gps !== null && gps.length > 0) {
      if (gps.length >= this.size) {
        this.loadmoreg = true;
      } else {
        this.loadmoreg = false;
      }

      for (const val of gps) {
        const newg = new ContactGroupDefInfo();
        newg.GroupId = val.groupId;
        newg.GroupName = val.groupName;
        newg.GroupDesc = val.groupDesc;
        newg.GroupPerm = val.groupPerm;
        newg.Color = 'transparent';
        newg.Icon = 'pi pi-angle-right';

        this.groups.push(newg);
      }

      if (this.pageg === 1) {
        await this.groupClicked(this.groups[0]);
      }
    } else {
      if (this.pageg === 1) {
        this.isnogroups = true;
      } else {
        this.loadmoreg = false;
      }
    }
    this.loading = false;
  }

  async getContactGroups(): Promise<any> {
    this.loading = true;
    this.contactgroups = [];
    const gps = await this.groupDefService.getGroups(0, 0, 0, ' ', ' ').toPromise()
    .catch(err => this.handleError(err));

    if (gps !== undefined && gps !== null && gps.length > 0) {
      for (const val of gps) {
        this.contactgroups.push({ label: val.groupName, value: val });
      }
    }
    this.loading = false;
  }

  async showContacts(group: any): Promise<any> {
    this.loading = true;
    if (group.ShowContacts === true) {
      for (const val of this.groups) {
        if (val.GroupId === group.GroupId) {
          val.Color = 'lightgray';
          val.Icon = 'pi pi-angle-right';
          val.ShowContacts = false;
        } else {
          val.Color = 'transparent';
          val.Icon = 'pi pi-angle-right';
          val.ShowContacts = false;
        }
      }

      this.loading = false;
      return;
    } else {
      this.contacts = [];
      this.pagec = 1;
      await this.getContacts(group);
    }
  }

  async getContacts(group: any): Promise<any> {
    this.loading = true;
    this.iscontacts = false;
    this.group = group;

    let value = ' ';
    if (this.filtervalue !== undefined && this.filtervalue !== null && this.filtervalue.trim() !== '') {
      value = this.filtervalue;
    }

    const conts = await this.contactService.getContacts(this.pagec, this.size, 0, value, group.GroupId, ' ').toPromise()
    .catch(err => this.handleError(err));

    if (conts !== undefined && conts !== null && conts.length > 0) {
      if (conts.length >= this.size) {
        this.loadmorec = true;
      } else {
        this.loadmorec = false;
      }

      for (const val of conts) {
        const newcontact = this.prepareContact(val);

        this.contacts.push(newcontact);
      }
    } else {
      if (this.pagec > 1) {
        this.loadmorec = false;
      }
    }

    for (const val of this.groups) {
      if (val.GroupId === group.GroupId) {
        val.Color = 'lightgray';
        val.Icon = 'pi pi-angle-down';
        val.ShowContacts = true;
      } else {
        val.Color = 'transparent';
        val.Icon = 'pi pi-angle-right';
        val.ShowContacts = false;
      }
    }

    this.iscontacts = true;
    this.loading = false;
  }

  prepareContact(val: any): any {
    const newcontact = new NewContactInfo();
    newcontact.Color = 'transparent';
    newcontact.CompanyName = val.companyName;

    newcontact.Contact.Company = val.contact.company;
    newcontact.Contact.ContactId = val.contact.contactId;
    newcontact.Contact.ContactType = val.contact.contactType;
    newcontact.Contact.DoB = val.contact.doB;
    newcontact.Contact.FirstName = val.contact.firstName;
    newcontact.Contact.LastName = val.contact.lastName;
    newcontact.Contact.Name = val.contact.name;
    newcontact.Contact.Picture = val.contact.picture;
    newcontact.Contact.Title = val.contact.title;

    const doptions: any = { year: 'numeric', month: 'short', day: 'numeric' };
    newcontact.DateofBirth = new Date(val.contact.doB).toLocaleString('en-US', doptions);

    if (val.contact.picture !== undefined && val.contact.picture !== null && val.contact.picture.trim() !== '') {
      newcontact.Image = this.domSanitizer.bypassSecurityTrustUrl(val.contact.picture);
    } else {
      newcontact.Image = 'assets/images/user.png';
    }

    newcontact.Group = val.group;

    newcontact.Locations = val.locations;
    newcontact.Channels = val.channels;
    return newcontact;
  }

  prepareContact2(val: any): any {
    const newcontact = new NewContactInfo();
    newcontact.Color = 'transparent';
    newcontact.CompanyName = val.CompanyName;

    newcontact.Contact.Company = val.Contact.Company;
    newcontact.Contact.ContactId = val.Contact.ContactId;
    newcontact.Contact.ContactType = val.Contact.ContactType;
    newcontact.Contact.DoB = val.Contact.DoB;
    newcontact.Contact.FirstName = val.Contact.FirstName;
    newcontact.Contact.LastName = val.Contact.LastName;
    newcontact.Contact.Name = val.Contact.Name;
    newcontact.Contact.Picture = val.Contact.Picture;
    newcontact.Contact.Title = val.Contact.Title;

    const doptions: any = { year: 'numeric', month: 'short', day: 'numeric' };
    newcontact.DateofBirth = new Date(val.Contact.DoB).toLocaleString('en-US', doptions);

    if (val.Contact.Picture !== undefined && val.Contact.Picture !== null && val.Contact.Picture.trim() !== '') {
      newcontact.Image = this.domSanitizer.bypassSecurityTrustUrl(val.Contact.Picture);
    } else {
      newcontact.Image = 'assets/images/user.png';
    }

    newcontact.Group = val.Group;

    newcontact.Locations = val.Locations;
    newcontact.Channels = val.Channels;
    return newcontact;
  }

  async getCompanies(): Promise<any> {
    this.loading = true;

    this.companies = [];

    const conts = await this.contactService.getContacts(0, 0, 0, ' ', 0, 'Company')
    .toPromise().catch(err => this.handleError(err));

    if (conts !== undefined && conts !== null && conts.length > 0) {
      for (const val of conts) {
        this.companies.push({ label: val.contact.name, value: val.contact});
        if (this.CompanyId !== undefined && this.CompanyId !== null && this.CompanyId > 0) {
          if (val.contact.contactId === this.CompanyId) {
            this.Company = val.contact;
          }
        }
      }
    }

    this.loading = false;
  }

  async contactClicked(contact: any): Promise<any> {
    const conts = [];
    for (const val of this.contacts) {
      const cont = this.prepareContact2(val);
      if (val.Contact.ContactId === contact.Contact.ContactId) {
        val.Color = 'lightgray';
        cont.Color = 'lightgray';
      } else {
        val.Color = 'transparent';
        cont.Color = 'transparent';
      }
      conts.push(cont);
    }

    this.contacts = conts;
    this.contact = contact;

    await this.getContactChannels();
    await this.getContactLocations();

    this.isgroupdetail = false;
    this.iscontactdetail = true;
  }

  async apply(): Promise<any> {
    this.groups = [];
    this.pageg = 1;
    await this.getGroups();
  }

  async groupClicked(group: any): Promise<any> {
    const mygroups = [];
    for (const val of this.groups) {
      const newg = new ContactGroupDefInfo();
      newg.GroupId = val.GroupId;
      newg.GroupName = val.GroupName;
      newg.GroupDesc = val.GroupDesc;
      newg.GroupPerm = val.GroupPerm;
      newg.Color = 'transparent';
      newg.Icon = 'pi pi-angle-right';

      if (val.GroupId === group.GroupId) {
        newg.Color = 'lightgray';
      } else {
        newg.Color = 'transparent';
      }
      mygroups.push(newg);
    }
    this.groups = [];
    this.groups = mygroups;

    this.group = group;

    await this.getGroupPerms();

    this.isgroupdetail = true;
    this.iscontactdetail = false;
  }

  async loadMoreG(): Promise<any> {
    this.pageg += 1;
    await this.getGroups();
  }

  async loadMoreC(): Promise<any> {
    this.pagec += 1;
    await this.getContacts(this.group);
  }

  startEditMode(): any {
    this.editmode = true;
    this.divname = 'disableddiv';
  }

  exitEditMode(): any {
    this.editmode = false;
    this.divname = 'notdisableddiv';
  }

  async showEditGroup(): Promise<any> {
    this.GroupName = this.group.GroupName;
    this.GroupDesc = this.group.GroupDesc;

    this.editgroup = true;
  }

  async showEditContact(): Promise<any> {
    this.ContactType = this.contact.Contact.ContactType;
    this.CompanyId = this.contact.Contact.Company;
    this.DoB = new Date(this.contact.Contact.DoB);
    this.FirstName = this.contact.Contact.FirstName;
    this.LastName = this.contact.Contact.LastName;
    this.CompanyName = this.contact.Contact.Name;
    this.Picture = this.contact.Contact.Picture;
    this.pic = this.domSanitizer.bypassSecurityTrustUrl(this.contact.Contact.Picture);
    this.picturename = '';
    this.ContactTitle = this.contact.Contact.Title;

    await this.getContactGroups();
    // await this.getChannelDef();
    await this.getCompanies();
    await this.getContactLocations();
    await this.getContactChannels();

    this.ContactGroup = this.contact.Group;

    this.addgroup = false;
    this.addcontact = false;

    this.editcontact = true;
  }

  async getChannelDef(): Promise<any> {
    this.loading = true;

    this.singlescreate = [];
    this.multiplescreate = [];

    const defs = await this.channelDefService.getChannelDefs(0, 0, 0, ' ').toPromise()
    .catch(err => this.handleError(err));

    if (defs !== undefined && defs !== null && defs.length > 0) {
      for (const val of defs) {
        if (val.entryAmount === 'SINGLE') {
          val.setValue = '';
          val.iserror = false;

          const chan = new ContactChannelData();
          chan.ContactChannelTypeId = val.channelTypeId;
          chan.Def = val;
          chan.ChannelValue = '';
          chan.IsError = false;
          // this.singlescreate.push(val);
          this.singlescreate.push(chan);
        } else if (val.entryAmount === 'MULTIPLE') {
          val.setValues = [];
          val.iserror = false;

          const citems: MenuItem[] = [];
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.EDIT').subscribe((resp2: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
              citems.push({label: resp2, id: 'edit',
              command: async (event) => { await this.modifyItemCreate(val, 'edit'); } });
              citems.push({label: resp3, id: 'delete',
              command: async (event) => { await this.modifyItemCreate(val, 'delete'); } });

              val.menu = citems;
              this.multiplescreate.push(val);
            });
          });
        }
      }
    }

    this.loading = false;
  }

  async getContactChannels(): Promise<any> {
    this.loading = true;

    const chans = await this.contChanService.getContactChannels(0, 0, this.contact.Contact.ContactId,
    this.group.GroupId).toPromise().catch(err => this.handleError(err));

    this.channels = [];

    this.singlesedit = [];
    this.multiplesedit = [];
    const newmultiplesedit = [];

    if (chans !== undefined && chans !== null && chans.length > 0) {
      for (const val of chans) {
        const chaninfo = new ContactChannelInfo();

        const chan = new ContactChannel();
        chan.ChannelValue = val.channel.channelValue;
        chan.ContactChannelTypeId = val.channel.contactChannelTypeId;
        chan.ContactId = val.channel.contactId;
        chan.FavoriteValue = val.channel.favoriteValue;
        chaninfo.Channel = chan;

        const chantype = new ContactChannelDef();
        chantype.ChannelType = val.channelDef.channelType;
        chantype.ChannelTypeId = val.channelDef.channelTypeId;
        chantype.ChannelTypeName = val.channelDef.channelTypeName;
        chantype.EntryAmount = val.channelDef.entryAmount;
        chaninfo.ChannelDef = chantype;

        this.channels.push(chaninfo);

        const newinfo = new ContactChannelInfoData();
        newinfo.Channel = chan;
        newinfo.ChannelDef = chantype;
        newinfo.IsError = false;
        newinfo.Value = val.channel.channelValue;
        if (chantype.EntryAmount === 'SINGLE') {
          this.singlesedit.push(newinfo);
        } else if (chantype.EntryAmount === 'MULTIPLE') {
          newmultiplesedit.push(newinfo);
        }
      }
    }

    const grouped = this.groupBy(newmultiplesedit, (mult: any) => mult.ChannelDef.ChannelTypeName);
    for (const val of grouped) {
      val[1].sort((a: any, b: any) => a.Channel.ChannelValue.localeCompare(b.Channel.ChannelValue));
      const citems: MenuItem[] = [];
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EDIT').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
          citems.push({label: resp2, id: 'edit',
          command: async (event) => { await this.modifyItemEdit(val, 'edit'); } });
          citems.push({label: resp3, id: 'delete',
          command: async (event) => { await this.modifyItemEdit(val, 'delete'); } });

          val.menu = citems;
          this.multiplescreate.push(val);
        });
      });
      this.multiplesedit.push(val);
    }
    this.loading = false;
  }

  groupBy(list: any, keyGetter: any): any {
    const map = new Map();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  async getContactLocations(): Promise<any> {
    this.loading = true;

    const locs = await this.contLocService.getContactLocations(0, 0, this.contact.Contact.ContactId, 0,
      this.group.GroupId).toPromise().catch(err => this.handleError(err));

    this.locations = [];
    this.locs = [];
    this.locnames = [];

    if (locs !== undefined && locs !== null && locs.length > 0) {
      for (const val of locs) {
        const locinfo = new ContactLocationInfo();

        const loc = new ContactLocation();
        loc.ContactId = val.location.contactId;
        loc.FavoriteValue = val.location.favoriteValue;
        loc.LocationId = val.location.locationId;
        locinfo.Location = loc;

        const zone = new Zone();
        zone.GpsLatitude = val.zone.gpsLatitude;
        zone.GpsLongitude = val.zone.gpsLongitude;
        zone.ZoneDesc = val.zone.zoneDesc;
        zone.ZoneId = val.zone.zoneId;
        zone.ZoneName = val.zone.zoneName;
        zone.ZoneTypeId = val.zone.zoneTypeId;
        locinfo.Zone = zone;

        const ztype = new ZoneType();
        ztype.ZoneTypeId = val.zoneType.zoneTypeId;
        ztype.ZoneTypeName = val.zoneType.zoneTypeName;
        ztype.ZoneTypeType = val.zoneType.zoneTypeType;
        locinfo.ZoneType = ztype;

        this.locations.push(locinfo);

        const citems: MenuItem[] = [];
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.EDIT').subscribe((resp2: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
            // citems.push({label: resp2, id: 'edit',
            // command: async (event) => { await this.showDeleteLocCreate(zone); } });
            citems.push({label: resp3, id: 'delete',
            command: async (event) => { await this.showDeleteLocEdit(locdata); } });

            const locdata = new ContactLocationData();
            locdata.Menu = citems;
            if (this.locs.length === 0) {
              locdata.FavoriteValue = 'Y';
            } else {
              locdata.FavoriteValue = 'N';
            }

            locdata.LocationId = loc.LocationId;
            locdata.Name = zone.ZoneName;
            locdata.Zone = zone;
            locdata.ContactId = loc.ContactId;
            locdata.FavoriteValue = loc.FavoriteValue;
            this.locs.push(locdata);
            this.locnames.push(zone.ZoneName);
            // this.locs.sort((a, b) => a.zoneName.localeCompare(b.zoneName));
            this.showlocs = false;
          });
        });
      }
    }

    // const grouped = this.groupBy(this.locs, (loc: any) => loc.Name);
    this.loading = false;
  }

  async getGroupPerms(): Promise<any> {
    this.loading = true;
    this.readperms = [];
    this.writeperms = [];
    this.deleteperms = [];

    this.usergroupsdelete = [];
    this.usergroupsread = [];
    this.usergroupswrite = [];

    const rperms = await this.groupPermService.getGroupPerms(this.group.GroupId, 'R')
    .toPromise().catch(err => this.handleError(err));
    const wperms = await this.groupPermService.getGroupPerms(this.group.GroupId, 'W')
    .toPromise().catch(err => this.handleError(err));
    const dperms = await this.groupPermService.getGroupPerms(this.group.GroupId, 'D')
    .toPromise().catch(err => this.handleError(err));

    if (rperms !== undefined && rperms !== null && rperms.length > 0) {
      for (const val of rperms) {
        const perm = new GroupPerm();
        perm.GroupId = val.groupId;
        perm.Permission = val.permission;
        perm.UserGroup = val.userGroup;

        this.readperms.push(perm);
        this.usergroupsread.push(val.userGroup);
      }
    }

    if (wperms !== undefined && wperms !== null && wperms.length > 0) {
      for (const val of wperms) {
        const perm = new GroupPerm();
        perm.GroupId = val.groupId;
        perm.Permission = val.permission;
        perm.UserGroup = val.userGroup;

        this.writeperms.push(perm);
        this.usergroupswrite.push(val.userGroup);
      }
    }

    if (dperms !== undefined && dperms !== null && dperms.length > 0) {
      for (const val of dperms) {
        const perm = new GroupPerm();
        perm.GroupId = val.groupId;
        perm.Permission = val.permission;
        perm.UserGroup = val.userGroup;

        this.deleteperms.push(perm);
        this.usergroupsdelete.push(val.userGroup);
      }
    }

    this.loading = false;
  }

  async createGroup(): Promise<any> {
    this.groupnameerror = false;
    this.groupdescerror = false;
    this.usergrouperror = false;

    let iserror = false;

    if (this.GroupName === undefined || this.GroupName === null || this.GroupName.trim() === '') {
      this.groupnameerror = true;
      iserror = true;
    }

    if (this.GroupDesc === undefined || this.GroupDesc === null || this.GroupDesc.trim() === '') {
      this.groupdescerror = true;
      iserror = true;
    }

    if (this.UserGroup === undefined || this.UserGroup === null || this.UserGroup === '') {
      this.usergrouperror = true;
      iserror = true;
    }

    if (iserror === false) {
      this.loading = true;
      const group = new ContactGroupDef();
      group.GroupName = this.GroupName;
      group.GroupDesc = this.GroupDesc;
      group.GroupPerm = this.UserGroup.groupName;

      const resp = await this.groupDefService.postGroup(group).toPromise()
      .catch(err => this.handleError(err));

      if (resp === undefined || resp === null || resp <= 0) {
        // error
        // this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
        //   this.translate.get('GENERAL.GROUPNOTCREATED').subscribe((text: string) => {
        //     this.showError(title, text);
        //   });
        // });
      } else {
        // success
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.GROUPCREATED').subscribe((text: string) => {
            this.showSuccess(title, text);
          });
        });

        this.cancel();

        this.pageg = 1;
        this.groups = [];
        this.getGroups();
      }

      this.loading = false;
    }
  }

  async saveGroup(): Promise<any> {
    this.groupnameerror = false;
    this.groupdescerror = false;

    let iserror = false;

    if (this.GroupName === undefined || this.GroupName === null || this.GroupName.trim() === '') {
      this.groupnameerror = true;
      iserror = true;
    }

    if (this.GroupDesc === undefined || this.GroupDesc === null || this.GroupDesc.trim() === '') {
      this.groupdescerror = true;
      iserror = true;
    }

    if (iserror === false) {
      this.loading = true;
      const val = new Int64Updater();
      val.Id = this.group.GroupId;
      val.Values = [];

      const name = new KeyValue();
      name.Key = 'name';
      name.Value = this.GroupName as string;
      val.Values.push(name);

      const desc = new KeyValue();
      desc.Key = 'desc';
      desc.Value = this.GroupDesc as string;
      val.Values.push(desc);

      const resp = await this.groupDefService.putGroup(val).toPromise()
      .catch(err => this.handleError(err));

      if (resp === undefined || resp === null || resp === false) {
        // error
        // this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
        //   this.translate.get('GENERAL.GROUPNOTCREATED').subscribe((text: string) => {
        //     this.showError(title, text);
        //   });
        // });
      } else {
        // success
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.GROUPUPDATED').subscribe((text: string) => {
            this.showSuccess(title, text);
          });
        });

        this.cancel();

        this.pageg = 1;
        this.groups = [];
        this.getGroups();
      }

      this.loading = false;
    }
  }

  async addPermission(group: any): Promise<any> {
    this.loading = true;

    const groupperm = new GroupPerm();
    groupperm.GroupId = this.group.GroupId;
    groupperm.UserGroup = group.groupName;

    if (this.perm === 'read') {
      // add read permission
      groupperm.Permission = 'R';
    } else if (this.perm === 'write') {
      // add write permission
      groupperm.Permission = 'W';
    } else if (this.perm === 'delete') {
      // add delete permission
      groupperm.Permission = 'D';
    } else {
      return;
    }

    const resp = await this.groupPermService.postGroupPerm(groupperm).toPromise()
    .catch(err => this.handleError(err));

    if (resp !== undefined && resp !== null && resp === true) {
      // success
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.PERMISSIONADDED').subscribe((text: string) => {
          this.showSuccess(title, text);
        });
      });

      this.cancel();
      await this.getGroupPerms();
    }

    this.loading = false;
  }

  async createContact(): Promise<any> {
    this.loading = true;

    this.contactgrouperror = false;
    this.contacttypeerror = false;
    this.firstnameerror = false;
    this.lastnameerror = false;
    this.contacttitleerror = false;
    this.doberror = false;
    this.docerror = false;
    this.pictureerror = false;
    this.companynameerror = false;
    this.companyerror = false;
    this.locationerror = false;

    for (const val of this.singlescreate) {
      val.IsError = false;
    }

    for (const val of this.multiplescreate) {
      val.iserror = false;
    }

    let iserror = false;

    if (this.ContactGroup === undefined || this.ContactGroup === null || this.ContactGroup.length === 0) {
      this.contactgrouperror = true;
      iserror = true;
    }

    if (this.ContactType === undefined || this.ContactType === null || this.ContactType === '' || this.ContactType.length === 0) {
      this.contacttypeerror = true;
      iserror = true;
    }

    if (this.ContactType !== undefined && this.ContactType !== null && this.ContactType === 'Person') {
      if (this.FirstName === undefined || this.FirstName === null || this.FirstName.trim() === '') {
        this.firstnameerror = true;
        iserror = true;
      }

      if (this.LastName === undefined || this.LastName === null || this.LastName.trim() === '') {
        this.lastnameerror = true;
        iserror = true;
      }

      if (this.ContactTitle === undefined || this.ContactTitle === null || this.ContactTitle.trim() === '') {
        this.contacttitleerror = true;
        iserror = true;
      }

      if (this.DoB === undefined || this.DoB === null) {
        this.doberror = true;
        iserror = true;
      }

      if (this.Company === undefined || this.Company === null || this.Company.length === 0) {
        this.companyerror = true;
        iserror = true;
      }
    }

    if (this.ContactType !== undefined && this.ContactType !== null && this.ContactType === 'Company') {
      if (this.CompanyName === undefined || this.CompanyName === null || this.CompanyName === '') {
        this.companynameerror = true;
        iserror = true;
      }

      if (this.DoB === undefined || this.DoB === null) {
        this.docerror = true;
        iserror = true;
      }
    }

    for (const val of this.singlescreate) {
      if (val.ChannelValue === undefined || val.ChannelValue === null || val.ChannelValue.trim() === '') {
        val.IsError = true;
        iserror = true;
      }
    }

    for (const val of this.multiplescreate) {
      if (val.setValues === undefined || val.setValues === null || val.setValues.length === 0) {
        val.iserror = true;
        iserror = true;
      }
    }

    if (this.locs === undefined || this.locs === null || this.locs.length === 0) {
      this.locationerror = true;
      iserror = true;
    }

    if (this.Picture === undefined || this.Picture === null || this.Picture === '') {
      this.pictureerror = true;
      iserror = true;
    }

    if (iserror === false) {
      const contact = new ContactInfo();
      contact.Contact.ContactType = this.ContactType;
      if (this.ContactType === 'Person') {
        contact.Contact.FirstName = this.FirstName as string;
        contact.Contact.LastName = this.LastName as string;
        contact.Contact.Title = this.ContactTitle as string;

        contact.Contact.Company = this.Company.contactId;
      } else if (this.ContactType === 'Company') {
        contact.Contact.Name = this.CompanyName as string;
      }
      contact.Contact.DoB = new Date(this.DoB?.getFullYear() as number, this.DoB?.getMonth() as number,
                                     this.DoB?.getDate(), 10, 0, 0, 0);
      contact.Contact.Picture = this.Picture as string;

      // contact groups
      for (const val of this.ContactGroup) {
        const group = new ContactGroupDef();
        group.GroupId = val.groupId;
        group.GroupName = val.groupName;
        group.GroupDesc = val.groupDesc;
        contact.Group.push(group);
      }

      // locations
      for (const val of this.locs) {
        const loc = new ContactLocation();
        loc.LocationId = val.LocationId;
        loc.FavoriteValue = val.FavoriteValue;
        contact.Locations.push(loc);
      }

      // channels (single)
      for (const val of this.singlescreate) {
        const chan = new ContactChannel();
        chan.ContactChannelTypeId = val.ContactChannelTypeId;
        chan.ChannelValue = val.ChannelValue;
        chan.FavoriteValue = 'N';
        contact.Channels.push(chan);
      }

      // channels (multiple)
      for (const val of this.multiplescreate) {
        for (const sval of val.setValues) {
          const chan = new ContactChannel();
          chan.ContactChannelTypeId = val.channelTypeId;
          chan.ChannelValue = sval.ChannelValue;
          chan.FavoriteValue = sval.FavoriteValue;
          contact.Channels.push(chan);
        }
      }

      const res = await this.contactService.postContact(contact).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res > 0) {
        // success
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CONTACTCREATED').subscribe((text: string) => {
            this.showSuccess(title, text);
          });
        });

        this.cancel();
        if (this.group !== undefined && this.group !== null) {
          this.pagec = 1;
          this.contacts = [];
          this.getContacts(this.group);
        }
      }
    }

    this.loading = false;
  }

  async saveContact(): Promise<any> {
    this.loading = true;

    this.contactgrouperror = false;
    this.contacttypeerror = false;
    this.firstnameerror = false;
    this.lastnameerror = false;
    this.contacttitleerror = false;
    this.doberror = false;
    this.docerror = false;
    this.pictureerror = false;
    this.companynameerror = false;
    this.companyerror = false;
    this.locationerror = false;

    for (const val of this.singlescreate) {
      val.IsError = false;
    }

    for (const val of this.multiplescreate) {
      val.iserror = false;
    }

    let iserror = false;

    if (this.ContactGroup === undefined || this.ContactGroup === null || this.ContactGroup.length === 0) {
      this.contactgrouperror = true;
      iserror = true;
    }

    if (this.ContactType === undefined || this.ContactType === null || this.ContactType === '' || this.ContactType.length === 0) {
      this.contacttypeerror = true;
      iserror = true;
    }

    if (this.ContactType !== undefined && this.ContactType !== null && this.ContactType === 'Person') {
      if (this.FirstName === undefined || this.FirstName === null || this.FirstName.trim() === '') {
        this.firstnameerror = true;
        iserror = true;
      }

      if (this.LastName === undefined || this.LastName === null || this.LastName.trim() === '') {
        this.lastnameerror = true;
        iserror = true;
      }

      if (this.ContactTitle === undefined || this.ContactTitle === null || this.ContactTitle.trim() === '') {
        this.contacttitleerror = true;
        iserror = true;
      }

      if (this.DoB === undefined || this.DoB === null) {
        this.doberror = true;
        iserror = true;
      }

      if (this.Company === undefined || this.Company === null || this.Company.length === 0) {
        this.companyerror = true;
        iserror = true;
      }
    }

    if (this.ContactType !== undefined && this.ContactType !== null && this.ContactType === 'Company') {
      if (this.CompanyName === undefined || this.CompanyName === null || this.CompanyName === '') {
        this.companynameerror = true;
        iserror = true;
      }

      if (this.DoB === undefined || this.DoB === null) {
        this.docerror = true;
        iserror = true;
      }
    }

    for (const val of this.singlesedit) {
      if (val.Channel.ChannelValue === undefined || val.Channel.ChannelValue === null || val.Channel.ChannelValue.trim() === '') {
        val.IsError = true;
        iserror = true;
      }
    }

    if (this.Picture === undefined || this.Picture === null || this.Picture === '') {
      this.pictureerror = true;
      iserror = true;
    }

    if (iserror === false) {
      const value = new Int64Updater();
      value.Id = this.contact.Contact.ContactId;
      value.Values = [];
      value.AdditionalValues = this.group.GroupId.toString();
      if (this.ContactType === 'Person') {
        const fname = new KeyValue();
        fname.Key = 'fname';
        fname.Value = this.FirstName as string;
        value.Values.push(fname);

        const lname = new KeyValue();
        lname.Key = 'lname';
        lname.Value = this.LastName as string;
        value.Values.push(lname);

        const title = new KeyValue();
        title.Key = 'title';
        title.Value = this.ContactTitle as string;
        value.Values.push(title);

        const company = new KeyValue();
        company.Key = 'company';
        company.Value = this.Company.contactId;
        value.Values.push(company);
      } else if (this.ContactType === 'Company') {
        const companyname = new KeyValue();
        companyname.Key = 'companyname';
        companyname.Value = this.CompanyName as string;
        value.Values.push(companyname);
      }

      const dob = new KeyValue();
      dob.Key = 'dob';
      const date = new Date(this.DoB?.getFullYear() as number, this.DoB?.getMonth() as number,
                  this.DoB?.getDate(), 10, 0, 0, 0);
      dob.Value = JSON.stringify(date);
      value.Values.push(dob);

      const picture = new KeyValue();
      picture.Key = 'picture';
      picture.Value = this.Picture as string;
      value.Values.push(picture);

      // contact groups
      const data = new ContactGroupData();
      data.ContactId = this.contact.Contact.ContactId;
      for (const val of this.ContactGroup) {
        const group = new ContactGroupDef();
        group.GroupId = val.groupId;
        group.GroupName = val.groupName;
        group.GroupDesc = val.groupDesc;

        data.ContactGroup.push(group);
      }

      // channels (single)
      const values: Int64Updater[] = [];
      for (const val of this.singlesedit) {
        const cval = new Int64Updater();
        cval.Id = val.ChannelDef.ChannelTypeId;
        cval.Values = [];

        const v = new Variables();
        let vall: string | null;
        vall = localStorage.getItem('mg');
        const gps = CryptoJS.AES.decrypt(vall as string, v.pass).toString(CryptoJS.enc.Utf8).toString();

        cval.AdditionalValues = this.group.GroupId;
        cval.AdditionalValues2 = gps;
        cval.AdditionalValues3 = val.Value;

        const cvalue = new KeyValue();
        cvalue.Key = 'value';
        cvalue.Value = val.Channel.ChannelValue;
        cval.Values.push(cvalue);

        values.push(cval);
      }

      // update contact info
      const cres = await this.contactService.putContact(value).toPromise()
      .catch(err => this.handleError(err));
      // update contact groups
      const cgres = await this.cgroupService.postContactGroup(data).toPromise()
      .catch(err => this.handleError(err));
      // update channels data
      const ccres = await this.contChanService.putContactChannel(this.contact.Contact.ContactId, values)
      .toPromise().catch(err => this.handleError(err));
      if (cres !== undefined && cres !== null && cres === true
          && cgres !== undefined && cgres !== null && cgres === true
          && ccres !== undefined && ccres !== null && ccres === true) {
        // success
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CONTACTINFOUPDATED').subscribe((text: string) => {
            this.showSuccess(title, text);
          });
        });

        this.cancel();
        const cont = await this.contactService.getContacts(1, 1, this.contact.Contact.ContactId, ' ',
        this.group.GroupId, ' ').toPromise().catch(err => this.handleError(err));
        if (this.group !== undefined && this.group !== null) {
          if (cont !== undefined && cont !== null && cont.length > 0) {
            for (const val of cont) {
              const newcont = this.prepareContact(val);
              for (const cval of this.contacts) {
                if (cval.Contact.ContactId === val.contact.contactId) {
                  const vindex = this.contacts.indexOf(cval);
                  if (vindex > -1) {
                    this.contacts.splice(vindex, 1);
                    this.contacts.splice(vindex, 0, newcont);
                  }
                }
              }
              await this.contactClicked(newcont);
            }
          }
        }
      }
    }

    this.loading = false;
  }

  cancel(): any {
    this.contactgrouperror = false;
    this.contacttypeerror = false;
    this.firstnameerror = false;
    this.lastnameerror = false;
    this.contacttitleerror = false;
    this.doberror = false;
    this.docerror = false;
    this.pictureerror = false;
    this.companynameerror = false;
    this.companyerror = false;
    this.locationerror = false;

    this.groupnameerror = false;
    this.groupdescerror = false;
    this.usergrouperror = false;

    this.GroupName = '';
    this.GroupDesc = '';
    this.UserGroup = '';

    this.ContactGroup = [];
    this.ContactType = [];
    this.FirstName = '';
    this.LastName = '';
    this.ContactTitle = '';
    this.DoB = undefined;
    this.Company = [];
    this.singlescreate = [];
    this.multiplescreate = [];
    this.locs = [];
    this.Picture = '';
    this.picturename = '';
    this.pic = '';
    this.CompanyName = '';
    this.CompanyId = 0;

    this.addgroup = false;
    this.addcontact = false;
    this.editgroup = false;
    this.editcontact = false;
    this.perm = 'none';
    this.addperm = false;

    this.locnames = [];
    this.locs = [];

    this.cancelItem();
  }

  cancelItem(): any {
    this.additem = false;
    this.edititem = false;
    this.additemedit = false;
    this.edititemedit = false;
    this.value = [];
    this.ItemValue = '';
    this.typeid = 0;
    this.addtitle = '';
    // this.valueerror = false;
  }

  showDeleteReadPerm(perm: any): any {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DELETE').subscribe((text: string) => {
      this.confirm(text + ' ' + perm.UserGroup + '?', 'deleteperm', perm);
    });
  }

  showDeleteWritePerm(perm: any): any {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DELETE').subscribe((text: string) => {
      this.confirm(text + ' ' + perm.UserGroup + '?', 'deleteperm', perm);
    });
  }

  showDeleteDeletePerm(perm: any): any {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DELETE').subscribe((text: string) => {
      this.confirm(text + ' ' + perm.UserGroup + '?', 'deleteperm', perm);
    });
  }

  showAddItemCreate(value: any): any {
    this.valueerror = false;
    this.value = value;
    this.additem = true;
  }

  showAddItemEdit(value: any): any {
    if (value === undefined || value === null || value[0] === undefined || value[0] === null
      || value[1] === undefined || value[1] === null || value[1].length === 0) {
        console.log(false);
    } else {
      this.addtitle = value[0];
      this.typeid = value[1][0].ChannelDef.ChannelTypeId;
      this.typetype = value[1][0].ChannelDef.ChannelType;

      this.valueerror = false;
      this.value = value;
      this.additemedit = true;
    }
  }

  addItemCreate(): any {
    let iserror = false;
    if (this.ItemValue === undefined || this.ItemValue === null || this.ItemValue.trim() === '') {
      this.valueerror = true;
      iserror = true;
    }

    if (iserror === false) {
      const chan = new ContactChannel();
      chan.ChannelValue = this.ItemValue;
      const index = this.multiplescreate.indexOf(this.value, 0);
      if (index > -1) {
        for (const val of this.multiplescreate[index].setValues) {
          if (val.ChannelValue === this.ItemValue) {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.INFO').subscribe((title: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.VALUEEXISTS').subscribe((text2: string) => {
                this.showInfo(title, text2, 30000);
              });
            });
            return;
          }
        }
        if (this.value.channelType === 'email') {
          if (!this.ItemValue.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')) {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.INFO').subscribe((title: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.INVALIDEMAIL').subscribe((text2: string) => {
                this.showInfo(title, text2, 30000);
              });
            });
            return;
          }
        }
        // if (this.value.channelType === 'phone') {
        //   if (!this.ItemValue.match('/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im')) {
        //     this.translate.get('GENERAL.INFO').subscribe((title: string) => {
        //       this.translate.get('GENERAL.INVALIDPHONE').subscribe((text2: string) => {
        //         this.showInfo(title, text2, 30000);
        //       });
        //     });
        //     return;
        //   }
        // }
        if (this.multiplescreate[index].setValues.length === 0) {
          chan.FavoriteValue = 'Y';
        } else {
          chan.FavoriteValue = 'N';
        }
        this.multiplescreate[index].setValues.push(chan);
        // this.multiplescreate[index].setValues.push(this.ItemValue);
      }

      this.cancelItem();
    }
  }

  async addItemEdit(): Promise<any> {
    let iserror = false;
    if (this.ItemValue === undefined || this.ItemValue === null || this.ItemValue.trim() === '') {
      this.valueerror = true;
      iserror = true;
    }

    if (iserror === false) {
      const chan = new ContactChannel();
      chan.ChannelValue = this.ItemValue;
      chan.FavoriteValue = 'N';
      chan.ContactId = this.contact.Contact.ContactId;
      chan.ContactChannelTypeId = this.typeid as number;
      chan.GroupId = this.group.GroupId;

      if (this.typetype === 'email') {
        if (!this.ItemValue.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')) {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.INFO').subscribe((title: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.INVALIDEMAIL').subscribe((text2: string) => {
              this.showInfo(title, text2, 30000);
            });
          });
          return;
        }
      }
      // if (this.typetype === 'phone') {
      //   if (!this.ItemValue.match('/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im')) {
      //     this.translate.get('GENERAL.INFO').subscribe((title: string) => {
      //       this.translate.get('GENERAL.INVALIDPHONE').subscribe((text2: string) => {
      //         this.showInfo(title, text2, 30000);
      //       });
      //     });
      //     return;
      //   }
      // }

      this.loading = true;
      const res = await this.contChanService.postContactChannel(chan).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res === true) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ADDED').subscribe((text2: string) => {
            this.showInfo(title, this.addtitle + ' ' + text2, 3000);
          });
        });

        await this.getContactChannels();

        this.cancelItem();
        this.loading = false;
      }
    }
  }

  changeFavoriteItemCreate(value: any, item: any): any {
    if (item.FavoriteValue === 'N') {
      const chan = new ContactChannel();
      chan.ChannelValue = item.ChannelValue;
      chan.FavoriteValue = 'Y';

      const index = this.multiplescreate.indexOf(value, 0);
      if (index > -1) {
        const vindex = this.multiplescreate[index].setValues.indexOf(item);
        for (const val of this.multiplescreate[index].setValues) {
          val.FavoriteValue = 'N';
        }
        if (vindex > -1) {
          this.multiplescreate[index].setValues.splice(vindex, 1);
          this.multiplescreate[index].setValues.splice(vindex, 0, chan);
        }
      }
    }
  }

  async changeFavoriteItemEdit(myval: any, item: any): Promise<any> {
    if (item.Channel.FavoriteValue === 'N') {
      this.loading = true;
      const upt = new Int64Updater();
      upt.Id = item.Channel.ContactChannelTypeId;
      upt.Values = [];
      upt.AdditionalValues = this.group.GroupId.toString();
      upt.AdditionalValues3 = item.Channel.ChannelValue;

      const v = new Variables();
      let val: string | null;
      val = localStorage.getItem('mg');
      const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
      upt.AdditionalValues2 = gps;

      const value = new KeyValue();
      value.Key = 'fav_value';
      value.Value = 'Y';
      upt.Values.push(value);

      const values: Int64Updater[] = [];
      values.push(upt);

      const res = await this.contChanService.putContactChannel(item.Channel.ContactId, values)
      .toPromise().catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res === true) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.FAVORITEVALUECHANGED').subscribe((text2: string) => {
            this.showInfo(title, text2, 3000);
          });
        });

        await this.getContactChannels();
      }
    }
  }

  editItemCreate(): any {
    let iserror = false;
    if (this.ItemValue === undefined || this.ItemValue === null || this.ItemValue.trim() === '') {
      this.valueerror = true;
      iserror = true;
    }

    if (iserror === false) {
      const index = this.multiplescreate.indexOf(this.value, 0);
      if (index > -1) {
        let ivalue = [];
        for (const val of this.multiplescreate[index].setValues) {
          if (val.ChannelValue === this.modifyitemvalue) {
            ivalue = val;
          }
        }

        const vindex = this.multiplescreate[index].setValues.indexOf(ivalue);
        ivalue.ChannelValue = this.ItemValue;
        if (vindex > -1) {
          this.multiplescreate[index].setValues.splice(vindex, 1);
          this.multiplescreate[index].setValues.splice(vindex, 0, ivalue);
        }
      }

      this.cancelItem();
    }
  }

  modifyItemCreate(value: any, op: any): any {
    if (op === 'delete') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DELETE').subscribe((text: string) => {
        this.confirm(text + ' ' + this.modifyitemvalue + '?', 'deleteitemcreate', value);
      });
    } else if (op === 'edit') {
      this.ItemValue = this.modifyitemvalue;
      this.value = value;
      this.edititem = true;
    }
  }

  modifyItemEdit(value: any, op: any): any {
    if (op === 'delete') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DELETE').subscribe((text: string) => {
        this.confirm(text + ' ' + this.modifyitemedit.Channel.ChannelValue + '?', 'deleteitemedit', value);
      });
    } else if (op === 'edit') {
      this.valueerror = false;
      this.ItemValue = this.modifyitemedit.Channel.ChannelValue;
      this.addtitle = this.modifyitemedit.ChannelDef.ChannelTypeName;
      this.typeid = this.modifyitemedit.ChannelDef.ChannelTypeId;
      this.typetype = this.modifyitemedit.ChannelDef.ChannelType;
      this.value = value;
      this.edititemedit = true;
    }
  }

  async saveItemEdit(): Promise<any> {
    this.valueerror = false;
    let iserror = false;

    if (this.ItemValue === undefined || this.ItemValue === null || this.ItemValue.trim() === '') {
      iserror = false;
      this.valueerror = true;
      return;
    }

    if (this.typetype === 'email') {
      if (!this.ItemValue.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.INFO').subscribe((title: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.INVALIDEMAIL').subscribe((text2: string) => {
            this.showInfo(title, text2, 30000);
          });
        });
        return;
      }
    }
    // if (this.typetype === 'phone') {
    //   if (!this.ItemValue.match('/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im')) {
    //     this.translate.get('GENERAL.INFO').subscribe((title: string) => {
    //       this.translate.get('GENERAL.INVALIDPHONE').subscribe((text2: string) => {
    //         this.showInfo(title, text2, 30000);
    //       });
    //     });
    //     return;
    //   }
    // }

    this.loading = true;
    const upt = new Int64Updater();
    upt.Id = this.typeid as number;
    upt.Values = [];
    upt.AdditionalValues = this.group.GroupId.toString();
    upt.AdditionalValues3 = this.modifyitemedit.Channel.ChannelValue;

    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    upt.AdditionalValues2 = gps;

    const value = new KeyValue();
    value.Key = 'value';
    value.Value = this.ItemValue;
    upt.Values.push(value);

    const values: Int64Updater[] = [];
    values.push(upt);

    const res = await this.contChanService.putContactChannel(this.contact.Contact.ContactId, values)
    .toPromise().catch(err => this.handleError(err));
    if (res !== undefined && res !== null && res === true) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SAVED').subscribe((text2: string) => {
          this.showInfo(title, this.addtitle + ' ' + text2, 3000);
        });
      });

      await this.getContactChannels();
      this.cancelItem();
    }
  }

  setItem(item: any): any {
    this.modifyitemvalue = item;
  }

  setItemEdit(item: any): any {
    this.modifyitemedit = item;
  }

  showDeleteLocCreate(item: any): any {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DELETE').subscribe((text: string) => {
      this.confirm(text + ' ' + item.Name + '?', 'deleteloccreate', item);
    });
  }

  showDeleteLocEdit(item: any): any {
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DELETE').subscribe((text: string) => {
      this.confirm(text + ' ' + item.Name + '?', 'deletelocedit', item);
    });
  }

  async getZones(): Promise<any> {
    this.loading = true;

    this.zones = [];

    const zones = await this.zoneService.getZones(0, 0, 0, ' ').toPromise()
    .catch(err => this.handleError(err));

    if (zones !== undefined && zones !== null && zones.length > 0) {
      for (const val of zones) {
        if (this.locnames.includes(val.zone.zoneName) === false) {
          this.zones.push(val.zone);
        }
      }
    }

    this.loading = false;
  }

  async addLocCreate(zone: any): Promise<any> {
    if (this.addcontact === true) {
      const citems: MenuItem[] = [];
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EDIT').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
          // citems.push({label: resp2, id: 'edit',
          // command: async (event) => { await this.showDeleteLocCreate(zone); } });
          citems.push({label: resp3, id: 'delete',
          command: async (event) => { await this.showDeleteLocCreate(locdata); } });

          const locdata = new ContactLocationData();
          locdata.Menu = citems;
          if (this.locs.length === 0) {
            locdata.FavoriteValue = 'Y';
          } else {
            locdata.FavoriteValue = 'N';
          }

          locdata.LocationId = zone.zoneId;
          locdata.Name = zone.zoneName;
          locdata.Zone = zone;
          this.locs.push(locdata);
          this.locnames.push(zone.zoneName);
          // this.locs.sort((a, b) => a.zoneName.localeCompare(b.zoneName));
          this.showlocs = false;
        });
      });
    } else {
      this.loading = true;
      const cloc = new ContactLocation();
      cloc.GroupId = this.group.GroupId;
      cloc.ContactId = this.contact.Contact.ContactId;
      cloc.FavoriteValue = 'N';
      cloc.LocationId = zone.zoneId;

      const res = await this.contLocService.postLocation(cloc).toPromise()
      .catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res === true) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.LOCATIONADDED').subscribe((text2: string) => {
            this.showInfo(title, text2, 3000);
            this.addgroup = false;
          });
        });

        await this.getContactLocations();
      }

      this.loading = false;
    }
  }

  changeFavoriteLocCreate(item: any): any {
    if (item.FavoriteValue === 'N') {
      for (const val of this.locs) {
        if (val.LocationId === item.LocationId) {
          val.FavoriteValue = 'Y';
        } else {
          val.FavoriteValue = 'N';
        }
      }
    }
  }

  async changeFavoriteLocEdit(item: any): Promise<any> {
    if (item.FavoriteValue === 'N') {
      this.loading = true;
      const upt = new Int64Updater();
      upt.Id = item.LocationId;
      upt.Values = [];
      upt.AdditionalValues = this.group.GroupId.toString();

      const value = new KeyValue();
      value.Key = 'value';
      value.Value = 'Y';
      upt.Values.push(value);

      const res = await this.contLocService.putLocation(item.ContactId, upt)
      .toPromise().catch(err => this.handleError(err));
      if (res !== undefined && res !== null && res === true) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.FAVORITEVALUECHANGED').subscribe((text2: string) => {
            this.showInfo(title, text2, 3000);
          });
        });

        await this.getContactLocations();
      }
    }
  }

  async showAddLoc(): Promise<any> {
    await this.getZones();
    this.showlocs = true;
  }

  async showAddReadPerm(): Promise<any> {
    if (this.editmode === false) {
      return;
    }
    this.perm = 'read';
    await this.getUserGroupsPerm();
    this.addperm = true;
  }

  async showAddWritePerm(): Promise<any> {
    if (this.editmode === false) {
      return;
    }
    this.perm = 'write';
    await this.getUserGroupsPerm();
    this.addperm = true;
  }

  async showAddDeletePerm(): Promise<any> {
    if (this.editmode === false) {
      return;
    }
    this.perm = 'delete';
    await this.getUserGroupsPerm();
    this.addperm = true;
  }

  async getUserGroups(): Promise<any> {
    this.loading = true;
    let page = 0;
    let run = true;
    this.usergroups = [];
    while (run === true) {
      page += 1;
      const groups = await this.groupService.getGroups(page, 500).toPromise()
      .catch((err: any) => this.handleError(err));
      if (groups !== null && groups.length > 0) {
        for (const group of groups) {
          this.usergroups.push({ label: group.groupName + '  ', value: group });
        }
      } else {
        run = false;
      }
    }
    this.loading = false;
  }

  async getUserGroupsPerm(): Promise<any> {
    this.loading = true;
    let page = 0;
    let run = true;
    this.usergroups = [];
    while (run === true) {
      page += 1;
      const groups = await this.groupService.getGroups(page, 500).toPromise()
      .catch((err: any) => this.handleError(err));
      if (groups !== null && groups.length > 0) {
        for (const group of groups) {
          if (this.perm === 'read') {
            if (!this.usergroupsread.includes(group.groupName)) {
              this.usergroups.push(group);
            }
          }
          if (this.perm === 'write') {
            if (!this.usergroupswrite.includes(group.groupName)) {
              this.usergroups.push(group);
            }
          }
          if (this.perm === 'delete') {
            if (!this.usergroupsdelete.includes(group.groupName)) {
              this.usergroups.push(group, this.perm);
            }
          }
        }
      } else {
        run = false;
      }
    }

    this.loading = false;
  }

  async deletePerm(perm: any): Promise<any> {
    this.loading = true;

    const resp = await this.groupPermService.deleteGroupPerm(perm).toPromise()
    .catch(err => this.handleError(err));

    if (resp !== undefined && resp !== null && resp === true) {
      // success
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.PERMISSIONDELETED').subscribe((text: string) => {
          this.showSuccess(title, text);
        });
      });

      this.cancel();
      await this.getGroupPerms();
    }

    this.loading = false;
  }

  async deleteLocEdit(value: any): Promise<any> {
    this.loading = true;
    const upt = new Int64Updater();
    upt.Id = value.LocationId;
    upt.AdditionalValues = this.group.GroupId.toString();

    const res = await this.contLocService.deleteLocation(value.ContactId, upt)
    .toPromise().catch(err => this.handleError(err));
    if (res !== undefined && res !== null && res === true) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.LOCATIONDELETED').subscribe((text2: string) => {
          this.showInfo(title, text2, 3000);
          this.addgroup = false;
        });
      });

      await this.getContactLocations();
    }
    this.loading = false;
  }

  async deleteChannelItemEdit(value: any): Promise<any> {
    this.loading = true;
    const upt = new Int64Updater();
    upt.Id = this.modifyitemedit.Channel.ContactChannelTypeId;
    upt.AdditionalValues = this.group.GroupId.toString();
    upt.AdditionalValues3 = this.modifyitemedit.Channel.ChannelValue;

    const res = await this.contChanService.deleteContactChannel(this.modifyitemedit.Channel.ContactId, upt)
    .toPromise().catch(err => this.handleError(err));
    if (res !== undefined && res !== null && res === true) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SUCCESS').subscribe((title: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ITEMDELETED').subscribe((text2: string) => {
          this.showInfo(title, text2, 3000);
        });
      });

      await this.getContactChannels();
    }
    this.loading = false;
  }

  onFileSelected(): any {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.picturename = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Picture = base64;
        this.pic = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.picturename = '';
        this.Picture = '';
        this.pic = null;
      });
    }
  }

  onFileSelectedEdit(): any {
    const inputNode: any = document.querySelector('#file2');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      if (inputNode.files[0]) {
        reader.readAsDataURL(inputNode.files[0]);
      }

      reader.onloadend = (e: any) => {
        this.picturename = inputNode.files[0].name;
        const base64 = e.target.result;
        this.Picture = base64;
        this.pic = this.domSanitizer.bypassSecurityTrustUrl(base64);
      };
      reader.onerror = (() => {
        this.picturename = '';
        this.Picture = '';
        this.pic = null;
      });
    }
  }

  showSuccess(title: string, message: string, duration: number = 3000): any {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message, life: duration });
  }

  showInfo(title: string, message: string, duration: number = 3000): any {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message, life: duration });
  }

  showWarn(title: string, message: string, duration: number = 3000): any {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message, life: duration });
  }

  showError(title: string, message: string, duration: number = 3000): any {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message, life: duration });
  }

  async confirm(text: string, operation: string, value: any): Promise<any> {
    this.isdelete = true;
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
          // Accept logic
          if (operation != null && operation === 'deleteperm') {
            // delete permission
            await this.deletePerm(value);
          }
          if (operation != null && operation === 'deleteloccreate') {
            // check if location is favorite
            if (value.FavoriteValue === 'Y') {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.INFO').subscribe((title: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.CHANGEFAVORITELOCATION').subscribe((text2: string) => {
                  this.showWarn(title, text2, 30000);
                  this.addgroup = false;
                });
              });
              return;
            }
            // delete create location
            const index = this.locs.indexOf(value, 0);
            if (index > -1) {
              this.locs.splice(index, 1);
            }
            const nindex = this.locnames.indexOf(value.Name, 0);
            if (nindex > -1) {
              this.locnames.splice(nindex, 1);
            }
          }
          if (operation != null && operation === 'deleteitemcreate') {
            // delete create item
            const index = this.multiplescreate.indexOf(value, 0);
            if (index > -1) {
              let ivalue = [];
              for (const val of this.multiplescreate[index].setValues) {
                if (val.ChannelValue === this.modifyitemvalue) {
                  ivalue = val;
                }
              }
              const vindex = this.multiplescreate[index].setValues.indexOf(ivalue);
              if (vindex > -1) {
                if (this.multiplescreate[index].setValues[vindex].FavoriteValue === 'Y') {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.INFO').subscribe((title: string) => {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.CHANGEFAVORITE').subscribe((text2: string) => {
                      // tslint:disable-next-line: deprecation
                      this.translate.get('GENERAL.BEFOREDELETING').subscribe((text3: string) => {
                        this.showWarn(title, text2 + ' ' + value.channelTypeName + ' ' + text3, 30000);
                      });
                    });
                  });
                  return;
                }
                this.multiplescreate[index].setValues.splice(vindex, 1);
              }
            }
          }
          if (operation !== null && operation === 'deletelocedit') {
            if (value.FavoriteValue === 'Y') {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.INFO').subscribe((title: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.CHANGEFAVORITELOCATION').subscribe((text2: string) => {
                  this.showWarn(title, text2, 30000);
                  this.addgroup = false;
                });
              });
              return;
            } else {
              await this.deleteLocEdit(value);
            }
          }
          if (operation !== null && operation === 'deleteitemedit') {
            if (this.modifyitemedit.Channel.FavoriteValue === 'Y') {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.INFO').subscribe((title: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.CHANGEFAVORITE').subscribe((text2: string) => {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.BEFOREDELETING').subscribe((text3: string) => {
                    this.showWarn(title, text2 + ' ' + this.modifyitemedit.ChannelDef.ChannelTypeName + ' ' + text3, 30000);
                  });
                });
              });
              return;
            } else {
              await this.deleteChannelItemEdit(value);
            }
          }
          this.isdelete = false;
        },
        reject: () => {
          // Reject logic
          this.isdelete = false;
        }
    });
  }

  confirm2(text: string): any {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
          // Accept logic
        },
        reject: () => {
          // Reject logic
        },
        key: '2'
    });
  }

  handleError(err: any): any {
    this.loading = false;
    console.log(err);
    // this.cancel();
    if (err !== undefined && err !== null && err.error !== undefined && err.error !== null) {
      if (err.error.MessageText !== undefined && err.error.MessageText !== null) {
        if (err.error.MessageText === 'duplicate key value violates unique constraint "t_abm_cont_grp_def_group_name_key"') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.GROUPEXISTS').subscribe((text: string) => {
              this.showError(title, text, 30000);
              this.addgroup = false;
            });
          });
        }
        if (err.error.MessageText === 'duplicate key value violates unique constraint "t_abm_cont_channel_channel_type_id_channel_value_contact_id_key"') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.VALUEEXISTS').subscribe((text: string) => {
              this.showError(title, text, 30000);
              this.addgroup = false;
            });
          });
        }
      }

      if (err.error.Message !== undefined && err.error.Message !== null) {
        if (err.error.Message === 'User does not edit permission') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOEDITPERMISSION').subscribe((text: string) => {
              this.showError(title, text, 30000);
              this.addgroup = false;
            });
          });
        }
        if (err.error.Message === 'User does not have permission to delete') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NODELETEPERMISSION').subscribe((text: string) => {
              this.showError(title, text, 30000);
              this.addgroup = false;
            });
          });
        }
        if (err.error.Message === 'Cannot delete all permissions. At least one permission must always be assigned') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.CANNOTDELETEALLPERMS').subscribe((text: string) => {
              this.showError(title, text, 30000);
              this.addgroup = false;
            });
          });
        }
        if (err.error.Message.includes('user does not have edit permission for the contact group:')) {
          const vals = err.error.Message.split(':');
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOEDITPERMISSIONFORGROUP').subscribe((text: string) => {
              this.showError(title, text  + ' ' + vals[1], 30000);
              this.addgroup = false;
            });
          });
        }
        if (err.error.Message === 'user does not have edit permission for the contact group') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NOEDITPERMISSION').subscribe((text: string) => {
              this.showError(title, text, 30000);
              this.addgroup = false;
            });
          });
        }
        if (err.error.Message === 'user does not have delete permission for the contact group') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.ERROR').subscribe((title: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.NODELETEPERMISSION').subscribe((text: string) => {
              this.showError(title, text, 30000);
              this.addgroup = false;
            });
          });
        }
      }
    }
  }

}
