import { ContactChannelDef } from './contact-channel-def';

export class ContactChannel {
    public ContactChannelTypeId!: number;
    public ChannelValue!: string;
    public ContactId!: number;
    public FavoriteValue!: string;
    public GroupId!: number;
    public Groups!: string;
}

export class ContactChannelInfo {
    public Channel: ContactChannel = new ContactChannel();
    public ChannelDef: ContactChannelDef = new ContactChannelDef();
}

export class ContactChannelData {
    public ContactChannelTypeId!: number;
    public ChannelValue!: string;
    public ContactId!: number;
    public FavoriteValue!: string;
    public GroupId!: number;
    public Groups!: string;
    public Menu: any[] = [];
    public Def: ContactChannelDef | undefined;
    public IsError: boolean | undefined;
}

export class ContactChannelInfoData {
    public Channel: ContactChannel = new ContactChannel();
    public ChannelDef: ContactChannelDef = new ContactChannelDef();
    public IsError: boolean | undefined;
    public Value: string | undefined;
}
