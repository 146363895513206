import { ContactGroupDef } from './../../Models/contact-group-def';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/Helpers/key-value';
import { Variables } from 'src/app/Helpers/variables';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class GroupDefService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getGroups(page: number, size: number, groupid: number, name: string, filter: string): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();

    const url = this.apiUrl + '/api/contactgroupdef/' + page + '/' + size + '/' + groupid
     + '/' + name + '/' + filter + '/' + gps + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postGroup(group: ContactGroupDef): Observable<any> {
    return this.httpclient.post(this.apiUrl + '/api/contactgroupdef', group)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putGroup(value: Int64Updater): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();

    value.AdditionalValues = value.Id.toString();
    value.AdditionalValues2 = gps;
    return this.httpclient.put(this.apiUrl + '/api/contactgroupdef/' + value.Id, value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteGroup(value: Int64Updater): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();

    value.AdditionalValues = value.Id.toString();
    value.AdditionalValues2 = gps;
    return this.httpclient.put(this.apiUrl + '/api/contactgroupdef/delete/' + value.Id, value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
