import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  apiUrl: string;
  orgId: number | undefined;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.flmApiUrl;
  }

  getFiles(page: number, size: number, fileid: string, filename: string, bucketname: string, start: string, end: string): any {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/files/' + page + '/' + size + '/' + fileid + '/' + filename
     + '/' + bucketname + '/' + start + '/' + end + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getFile(bucketname: string, filename: string): any {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/files/' + bucketname + '/' + filename + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  downloadFile(url: string, type: string): any {
    let headers = new HttpHeaders();
    headers = headers.set('Remove', '');
    headers = headers.set('Accept', type);
    return this.httpclient.get(url, { headers, responseType: 'blob' });
  }

  postFile(file: any): any {
    return this.httpclient.post(this.apiUrl + '/api/files', file)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteFile(file: any): Observable<any> {
    const del = JSON.stringify(file);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/files/' + del, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
