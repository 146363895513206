import { ContactGroupDef } from './contact-group-def';

export class ContactGroup {
}

export class ContactGroupData {
    public ContactGroup: ContactGroupDef[] = [];
    public ContactId: number | undefined;
    public Groups: string | undefined;
}
