import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/Helpers/key-value';
import { ContactChannelDef } from 'src/app/Models/contact-channel-def';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelDefService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getChannelDefs(page: number, size: number, id: number, name: string): Observable<any> {
    const url = this.apiUrl + '/api/contactchanneldef/' + page + '/' + size + '/' + id
     + '/' + name + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postChannelDef(def: ContactChannelDef): Observable<any> {
    return this.httpclient.post(this.apiUrl + '/api/contactchanneldef', def)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putChannelDef(value: Int64Updater): Observable<any> {
    return this.httpclient.put(this.apiUrl + '/api/contactchanneldef/' + value.Id, value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteChannelDef(id: number): Observable<any> {
    return this.httpclient.delete(this.apiUrl + '/api/contactchanneldef/' + id)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
