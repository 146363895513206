import { Zone } from './zone';
import { ZoneType } from './zone-type';

export class ContactLocation {
    public ContactId!: number;
    public LocationId!: number;
    public FavoriteValue!: string;
    public GroupId!: number;
    public Groups!: string;
}

export class ContactLocationInfo
{
    public Location: ContactLocation = new ContactLocation();
    public Zone: Zone = new Zone();
    public ZoneType: ZoneType = new ZoneType();
}

export class ContactLocationData {
    public ContactId!: number;
    public LocationId!: number;
    public Name!: string;
    public FavoriteValue!: string;
    public GroupId!: number;
    public Groups!: string;
    public Menu: any[] = [];
    public Zone: any;
}
