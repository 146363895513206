<div class="wrapper" style="min-width: 1200px;">

  <!-- CONTACTS AND GROUPS -->
  <div class="x">
    <div [class]="divname" style="height: 100%; display: flex; flex-direction: column;
          border: 1px solid #d3cece;">
      <div>
        <p-card styleClass="p-card-shadow2">
          <div style="margin: -10px; margin-top: -20px; margin-bottom: -40px; margin-top: -30px;">
           <div class="headerdiv" style="margin-bottom: 5px;">
             <label style="font-weight: 545; font-size: 25px; margin: 5px;">
               {{ 'GENERAL.CONTACTSANDGROUPS' | translate }}
             </label>
           </div>
           <div style="border-bottom: 1px solid black; margin-bottom: 5px; height: 55px;">
            <div class="header">
              <div class="toolbar" role="banner">
                <img src="assets/images/filter.png" alt="{{ 'GENERAL.FILTER' | translate }}"
                      style="margin-top: -3px; margin-left: -7px; height: 30px;">
                <div class="spacer2"></div>
                  <div style="width: 65%;">
                    <form [formGroup]="filterform" (ngSubmit)="apply()">
                      <input id="filtervalue" type="text" 
                            pInputText style="width: 100%;"
                            formControlName="filter"
                            placeholder="{{ 'GENERAL.ENTERCONTACTORGROUPNAME' | translate }}">
                    </form>
                 </div>
                <div class="spacer2"></div>
                <button pButton type="button" label="{{ 'GENERAL.APPLY' | translate }}"
                        class="ui-button-raised ui-button-primary"
                        (click)="apply()" style="margin: 0px; width: 25%; min-width: 75px;
                        white-space: nowrap; text-overflow: ellipsis; display: block;"
                        id="apply">
                </button>
                
              </div>
            </div>
           </div>
           <button pButton type="button" icon="pi pi-plus" class="p-button-rounded"
                   style="float: right; margin-right: 0px;"
                   id="showadd"
                   (click)="menu.toggle($event)"></button>
           <p-menu #menu [popup]="true" [model]="items"></p-menu>
           <br><br>
           <p style="margin: 10px;"></p>
          </div>
        </p-card>
      </div>
      <div style="background-color: transparent; flex-grow: 1; overflow: scroll; padding: 5px;">
        <div style="margin-bottom: 25px;">
          <div *ngFor="let group of groups" id="groups">
            <p-card [style]="{margin: '5px', 'margin-bottom': '10px',
                    'background-color': group.Color}"
                  (click)="groupClicked(group)" id="{{group.GroupName}}" styleClass="hover">
              <div class="header" style="margin: -10px;">
                <div class="toolbar" role="banner">
                  <div style="float: left;">
                    <i [class]="group.Icon" style="font-size: 2rem; margin-top: 5px;"
                      (click)="showContacts(group)" id="icon{{group.GroupName}}"></i>
                  </div>
                  <div>
                    <label style="font-size: 17px; font-weight: bold;" class="pointer">{{ group.GroupName }}</label>
                    <br>
                    <label style="font-size: 15px;" class="pointer">{{ group.GroupDesc }}</label>
                  </div>
                </div>
              </div>
              </p-card>
              <div *ngIf="group.ShowContacts" style="padding-left: 25px;">
                <p *ngIf="contacts == undefined || contacts == null || contacts.length == 0">{{ 'GENERAL.NOCONTACTS' | translate }}</p>
              <div *ngFor="let contact of contacts" id="contacts">
                <p-card [style]="{margin: '5px', 'margin-bottom': '10px',
                          'background-color': contact.Color}"
                      (click)="contactClicked(contact)" 
                      styleClass="hover">
                  <div class="header" style="margin: -10px;">
                    <div class="toolbar" role="banner">
                      <div style="float: left;">
                        <img style="max-width: 75px; max-height: 100px; margin-right: 5px;" [src]="contact.Image"
                            id="image{{contact.Contact.FirstName}}">
                      </div>
                      <div class="spacer"></div> 
                      <div>
                        <span [ngSwitch]="contact.Contact.ContactType">
                          <div *ngSwitchCase="'Person'">
                            <div>
                              <label style="font-size: 17px; font-weight: bold;" class="pointer"
                                     id="{{contact.Contact.FirstName}} {{contact.Contact.LastName}}">
                                {{ contact.Contact.FirstName }} {{ contact.Contact.LastName }}
                              </label>
                              <br>
                              <label style="font-size: 15px; font-style: italic;" class="pointer">{{ contact.Contact.ContactType }}</label>
                              <br>
                              <label style="font-size: 15px;" class="pointer">{{ contact.Contact.Title }}</label>
                            </div>
                          </div>
                          <div *ngSwitchCase="'Company'">
                            <div>
                              <label style="font-size: 17px; font-weight: bold;" class="pointer"
                                     id="{{ contact.Contact.Name }}">
                                {{ contact.Contact.Name }}
                              </label>
                              <br>
                              <label style="font-size: 15px; font-style: italic;" class="pointer">{{ contact.Contact.ContactType }}</label>
                            </div>
                          </div>
                          <div *ngSwitchDefault>
                          <!-- default case -->
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  </p-card>
                </div>

                <button pButton type="button" label="{{ 'GENERAL.LOADMORECONTACTS' | translate }}"
                      style="float: right; margin-right: 5px; margin-bottom: 15px;"
                      [disabled]="!loadmorec" (click)="loadMoreC()"
                      *ngIf="contacts.length" id="loadmorec"></button>
                <br *ngIf="contacts.length"><br *ngIf="contacts.length">
              </div>
          </div>
      
          <button pButton type="button" label="{{ 'GENERAL.LOADMOREGROUPS' | translate }}"
                  style="float: left; margin-left: 5px; margin-bottom: 15px;"
                  [disabled]="!loadmoreg" (click)="loadMoreG()"
                  *ngIf="groups.length" id="loadmoreg"></button>
      
          <p *ngIf="isnogroups" style="margin-left: 10px;">{{ 'GENERAL.NOGROUPS' | translate }}</p>
          <br><br>
        </div>
      </div>
    </div>
 </div>

 <!-- DETAIL -->
 <div class="y">
   <div style="height: 100%;">
     <p-card [style]="{'height': '100%', 'overflow': 'scroll'}" styleClass="p-card-shadow">
       <!-- GROUP DETAIL -->
      <div style="margin-top: -15px; margin-bottom: -15px; margin-top: -30px;" *ngIf="isgroupdetail">
         <div class="headerdiv" style="margin-left: 3px; margin-right: 0px;">
           <label *ngIf="group" style="font-weight: bold; font-size: 22px; margin-left: 0px;
                   display: inline-block; width: 43%; vertical-align: middle;" class="ellipsis">
             <label id="grouptitle">{{group.GroupName}}</label>
           </label>
           <label style="display: inline-block; width: 23%; vertical-align: middle; text-align: center;
                         margin: 0px; padding: 0px;">
             <label style="background-color: yellow; display: inline-block; min-width: 150px;
                           font-size: 24px; font-weight: bold; text-align: center;"
                     *ngIf="editmode" id="editmodelabel">
               {{ 'GENERAL.EDITMODE' | translate }}
             </label>
           </label>
           <label style="display: inline-block; width: 33%; text-align: right; vertical-align: middle;"
                  *ngIf="!editmode">
             <button pButton type="button"
                     label="{{ 'GENERAL.STARTEDITMODE' | translate }}"
                     style="font-size: 14px; font-weight: bold; padding: -10px;"
                     (click)="startEditMode()" *ngIf="!editmode" id="starteditmode"></button>
           </label>
           <label style="display: inline-block; width: 33%; text-align: right; vertical-align: middle;"
                  *ngIf="editmode">
            <button pButton type="button"
                    label="{{ 'GENERAL.EXITEDITMODE' | translate }}"
                    style="font-size: 14px; font-weight: bold; padding: -10px;"
                    (click)="exitEditMode()" *ngIf="editmode" id="exiteditmode"></button>
           </label>
         </div>
         <div>
          <p-card styleClass="p-card-shadow" [style]="{'padding': '0px', 'margin-top': '5px'}">
            <div style="margin: -15px; margin-top: -40px;">
              <button pButton type="button" icon="pi pi-pencil" class="p-button-rounded"
                    style="float: right; margin-right: 5px;" id="showeditgroup"
                    (click)="showEditGroup()" *ngIf="editmode"></button>
            <p class="info">
              <label class="infotitle">{{ 'GENERAL.NAME' | translate }}</label>
              <label class="infodetail" id="infogroupname">{{group.GroupName}}</label>
            </p>
            <p class="info">
              <label class="infotitle">{{ 'GENERAL.DESCRIPTION' | translate }}</label>
              <label class="infodetail" id="infogroupdesc">{{group.GroupDesc}}</label>
            </p>
            </div>
          </p-card>
         </div>
         <p style="margin: 10px;"></p>
         <div>
           <div class="header">
             <div class="toolbar" role="banner">
              <!-- READ PERMISSIONS -->
              <div style="width: 100%;">
                <p-card [style]="{'height': '250px', 'width': '100%'}" styleClass="p-card-shadow">
                  <ng-template pTemplate="header">
                    <label style="padding: 5px; font-weight: bold;">{{ 'GENERAL.READPERMISSIONS' | translate }}</label>
                  </ng-template>
                  <div style="margin: -10px; margin-top: -30px;">
                    <p-scrollPanel [style]="{'height': '185px', 'width': '100%'}" styleClass="custombar">
                      <li style="list-style: none;" *ngFor="let item of readperms" class="hoveritem">
                        <div class="header">
                          <div class="toolbar" role="banner">
                            <label id="read{{item.UserGroup}}">{{ item.UserGroup }}</label>
                            <div class="spacer2"></div>
                            <span id="Title">
                              <button (click)="showDeleteReadPerm(item)" class="focus-none"
                                      id="deleteread{{item.UserGroup}}"
                                      [disabled]="!editmode"
                                      style="border: none; background-color: transparent;">
                                <label style="height: 50px; text-align: center; margin-right: 0px;
                                              padding: 5px;" class="pointer">
                                  <i class="pi pi-trash" style="font-size: 1.5em"></i>
                                </label>
                              </button>
                            </span>
                          </div>
                        </div>
                      </li>
                    </p-scrollPanel>
                  </div>
                  <ng-template pTemplate="footer">
                    <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
                            style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                            margin-right: -10px; margin-top: -10px;" class="p-button-raised"
                            (click)="showAddReadPerm()"
                            [disabled]="!editmode"
                            id="addreadperm">
                    </button>
                    <!-- <p-button label="{{ 'GENERAL.ADD' | translate }}"
                              [style]="{'float': 'right', 'padding': '1px', 'padding-left': '5px',
                                        'padding-right': '5px', 'margin-right': '-10px',
                                        'margin-top': '-10px'}"
                              [disabled]="!editmode"
                              id="addreadperm"
                              (click)="showAddReadPerm()">
                    </p-button> -->
                </ng-template>
                </p-card>
              </div>
              <div class="spacer2"></div>
              <!-- WRITE PERMISSIONS -->
              <div style="width: 100%; margin-left: 10px; margin-right: 10px;">
                <p-card [style]="{'height': '250px', 'width': '100%'}" styleClass="p-card-shadow">
                  <ng-template pTemplate="header">
                    <label style="padding: 5px; font-weight: bold;">{{ 'GENERAL.WRITEPERMISSIONS' | translate }}</label>
                  </ng-template>
                  <div style="margin: -10px; margin-top: -30px;">
                    <p-scrollPanel [style]="{'height': '185px', 'width': '100%'}" styleClass="custombar">
                      <li style="list-style: none;" *ngFor="let item of writeperms" class="hoveritem">
                        <div class="header">
                          <div class="toolbar" role="banner">
                            <label id="write{{item.UserGroup}}">{{ item.UserGroup }}</label>
                            <div class="spacer2"></div>
                            <span id="Title">
                              <button (click)="showDeleteWritePerm(item)" class="focus-none"
                                      id="deletewrite{{item.UserGroup}}"
                                      [disabled]="!editmode"
                                      style="border: none; background-color: transparent;">
                                <label style="height: 50px; text-align: center; margin-right: 0px;
                                              padding: 5px;" class="pointer">
                                  <i class="pi pi-trash" style="font-size: 1.5em"></i>
                                </label>
                              </button>
                            </span>
                          </div>
                        </div>
                      </li>
                    </p-scrollPanel>
                  </div>
                  <ng-template pTemplate="footer">
                    <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
                            style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                            margin-right: -10px; margin-top: -10px;" class="p-button-raised"
                            (click)="showAddWritePerm()"
                            [disabled]="!editmode"
                            id="addwriteperm">
                    </button>
                    <!-- <p-button label="{{ 'GENERAL.ADD' | translate }}"
                              [style]="{'float': 'right', 'padding': '1px', 'padding-left': '5px',
                                        'padding-right': '5px', 'margin-right': '-10px', 
                                        'margin-top': '-10px'}"
                              [disabled]="!editmode"
                              id="addwriteperm"
                              (click)="showAddWritePerm()">
                    </p-button> -->
                </ng-template>
                </p-card>
              </div>
              <div class="spacer2"></div>
              <!-- DELETE PERMISSIONS -->
              <div style="width: 100%;">
                <p-card [style]="{'height': '250px', 'width': '100%'}" styleClass="p-card-shadow">
                  <ng-template pTemplate="header">
                    <label style="padding: 5px; font-weight: bold;">{{ 'GENERAL.DELETEPERMISSIONS' | translate }}</label>
                  </ng-template>
                  <div style="margin: -10px; margin-top: -30px;">
                    <p-scrollPanel [style]="{'height': '185px', 'width': '100%'}" styleClass="custombar">
                      <li style="list-style: none;" *ngFor="let item of deleteperms" class="hoveritem">
                        <div class="header">
                          <div class="toolbar" role="banner">
                            <label id="delete{{item.UserGroup}}">{{ item.UserGroup }}</label>
                            <div class="spacer2"></div>
                            <span id="Title">
                              <button (click)="showDeleteDeletePerm(item)" class="focus-none"
                                      id="deletedelete{{item.UserGroup}}"
                                      [disabled]="!editmode"
                                      style="border: none; background-color: transparent;">
                                <label style="height: 50px; text-align: center; margin-right: 0px;
                                              padding: 5px;" class="pointer">
                                  <i class="pi pi-trash" style="font-size: 1.5em"></i>
                                </label>
                              </button>
                            </span>
                          </div>
                        </div>
                      </li>
                    </p-scrollPanel>
                  </div>
                  <ng-template pTemplate="footer">
                    <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
                            style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                            margin-right: -10px; margin-top: -10px;" class="p-button-raised"
                            (click)="showAddDeletePerm()"
                            [disabled]="!editmode"
                            id="adddeleteperm">
                    </button>
                    <!-- <p-button label="{{ 'GENERAL.ADD' | translate }}"
                              [style]="{'float': 'right', 'padding': '1px', 'padding-left': '5px',
                                        'padding-right': '5px', 'margin-right': '-10px', 
                                        'margin-top': '-10px'}"
                              [disabled]="!editmode"
                              id="adddeleteperm"
                              (click)="showAddDeletePerm()">
                    </p-button> -->
                  </ng-template>
                </p-card>
              </div>
             </div>
           </div>
         </div>
       </div>

       <!-- CONTACT DETAIL -->
       <div style="margin-top: -15px; margin-bottom: -15px; margin-top: -22px;" *ngIf="iscontactdetail">
        <div class="headerdiv" style="margin-left: 3px; margin-right: 3px;">
          <label *ngIf="group" style="font-weight: bold; font-size: 22px; margin-left: 0px;
                  display: inline-block; width: 43%; vertical-align: middle;" class="ellipsis">
            <label *ngIf="contact.Contact.ContactType=='Person'">{{contact.Contact.FirstName}} {{contact.Contact.LastName}}</label>
            <label *ngIf="contact.Contact.ContactType=='Company'">{{contact.Contact.Name}}</label>
          </label>
          <label style="display: inline-block; width: 23%; vertical-align: middle; text-align: center;
                        margin: 0px; padding: 0px;">
            <label style="background-color: yellow; display: inline-block; min-width: 150px;
                          font-size: 24px; font-weight: bold; text-align: center;"
                    *ngIf="editmode" id="editmodelabel">
              {{ 'GENERAL.EDITMODE' | translate }}
            </label>
          </label>
          <label style="display: inline-block; width: 33%; text-align: right; vertical-align: middle;"
                 *ngIf="!editmode">
            <button pButton type="button"
                    label="{{ 'GENERAL.STARTEDITMODE' | translate }}"
                    style="font-size: 14px; font-weight: bold; padding: -10px;"
                    (click)="startEditMode()" *ngIf="!editmode" id="starteditmodec"></button>
          </label>
          <label style="display: inline-block; width: 33%; text-align: right; vertical-align: middle;"
                 *ngIf="editmode">
           <button pButton type="button"
                   label="{{ 'GENERAL.EXITEDITMODE' | translate }}"
                   style="font-size: 14px; font-weight: bold; padding: -10px;"
                   (click)="exitEditMode()" *ngIf="editmode" id="exiteditmode"></button>
          </label>
        </div>
        <div>
         <p-card styleClass="p-card-shadow" [style]="{'padding': '0px', 'margin-top': '5px'}">
           <div style="margin: -15px; margin-top: -40px;">
             <button pButton type="button" icon="pi pi-pencil" class="p-button-rounded"
                   style="float: right; margin-right: 5px;" id="showeditcontact"
                   (click)="showEditContact()" *ngIf="editmode"></button>
           <p class="info">
             <label class="infotitle">{{ 'GENERAL.NAME' | translate }}</label>
             <label class="infodetail" *ngIf="contact.Contact.ContactType=='Person'" id="infocontactname">{{contact.Contact.FirstName}} {{contact.Contact.LastName}}</label>
             <label class="infodetail" *ngIf="contact.Contact.ContactType=='Company'" id="infocompanyname">{{contact.Contact.Name}}</label>
           </p>
           <p class="info">
            <label class="infotitle">{{ 'GENERAL.TYPE' | translate }}</label>
            <label class="infodetail" id="infocontacttype">{{contact.Contact.ContactType}}</label>
          </p>
           <p class="info" *ngIf="contact.Contact.ContactType=='Person'">
             <label class="infotitle">{{ 'GENERAL.TITLE' | translate }}</label>
             <label class="infodetail" id="infocontacttitle">{{contact.Contact.Title}}</label>
           </p>
           <p class="info">
             <label class="infotitle" *ngIf="contact.Contact.ContactType=='Person'">{{ 'GENERAL.DATEOFBIRTH' | translate }}</label>
             <label class="infotitle" *ngIf="contact.Contact.ContactType=='Company'">{{ 'GENERAL.DATEOFCREATION' | translate }}</label>
             <label class="infodetail" id="infocontactdob">{{contact.DateofBirth}}</label>
           </p>
           <p class="info" *ngIf="contact.Contact.ContactType=='Person'">
             <label class="infotitle">{{ 'GENERAL.COMPANY' | translate }}</label>
             <label class="infodetail" id="infocontactcompany">{{contact.CompanyName}}</label>
           </p>
           <li *ngFor="let item of channels" style="list-style: none;" id="contactchannels">
            <p class="info">
              <label class="infotitle">{{ item.ChannelDef.ChannelTypeName }}</label>
              <label class="infodetail">{{item.Channel.ChannelValue}}</label>
              <i class="pi pi-star" style="color: #f7f723; font-size: 1.5rem; margin-right: 5px;" 
                  *ngIf="item.Channel.FavoriteValue=='Y'"></i>
            </p>
           </li>
           <li *ngFor="let item of locations" style="list-style: none;" class="item" id="contactlocs">
            <p class="info">
              <label class="infotitle">{{ 'GENERAL.LOCATION' | translate }}</label>
              <label class="infodetail">{{item.Zone.ZoneName}}</label>
              <i class="pi pi-star" style="color: #f7f723; font-size: 1.5rem; margin-right: 5px;"
                *ngIf="item.Location.FavoriteValue=='Y'"></i>
            </p>
           </li>
           </div>
         </p-card>
        </div>
      </div>
     </p-card>
  </div>
 </div>

</div>

<!-- CREATE GROUP -->
<p-dialog header="{{ 'GENERAL.CREATEGROUP' | translate }}" [(visible)]="addgroup"
          [modal]="true" [draggable]="false" [resizable]="false" #creategroupdialog>
    <div>
      <div class="p-field">
        <label for="groupname">{{ 'GENERAL.GROUPNAME' | translate }} (*)</label>
        <input id="groupname" type="username" aria-describedby="groupname-help"
                pInputText style="width: 100%;"
                placeholder="{{ 'GENERAL.ENTERGROUPNAME' | translate }}"
                [(ngModel)]="GroupName" />
        <small id="groupname-help" class="p-error" *ngIf="groupnameerror">
          {{ 'GENERAL.ENTERGROUPNAME' | translate }}
        </small>
      </div>
      <br>
      <div class="p-field">
        <label for="groupdesc">{{ 'GENERAL.GROUPDESC' | translate }} (*)</label>
        <textarea rows="5" cols="30" pInputTextarea [autoResize]="true"
                  [style]="{'min-height': '125px', 'width': '100%'}" id="groupdesc" 
                  placeholder="{{ 'GENERAL.ENTERGROUPDESC' | translate }}"
                  [(ngModel)]="GroupDesc" >
        </textarea>
        <small id="groupdesc-help" class="p-error" *ngIf="groupdescerror">
          {{ 'GENERAL.ENTERGROUPDESC' | translate }}
        </small>
      </div>
      <br>
      <div class="p-field">
        <label for="usergroup">{{ 'GENERAL.USERGROUP' | translate }} (*)</label>
        <p-dropdown [options]="usergroups" [(ngModel)]="UserGroup"
                    placeholder="{{ 'GENERAL.SELECTUSERGROUP' | translate }}"
                    [style]="{width:'100%'}" [appendTo]="creategroupdialog"
                    id="usergroup">
          </p-dropdown>
        <small id="usergroup-help" class="p-error" *ngIf="usergrouperror">
          {{ 'GENERAL.SELECTUSERGROUP' | translate }}
        </small>
      </div>
    </div>
    <br>
    <div>
      <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
              style="margin-right: 10px;" class="p-button-raised p-button-secondary"
              (click)="cancel()"
              [disabled]="loading"
              id="cancelgroup">
      </button>
      <button pButton type="button" label="{{ 'GENERAL.CREATE' | translate }}"
              style="margin-top: 5px;" class="p-button-raised"
              (click)="createGroup()"
              [disabled]="loading"
              id="creategroup">
      </button>
    </div>
</p-dialog>

<!-- EDIT GROUP -->
<p-dialog header="{{ 'GENERAL.EDITGROUP' | translate }}" [(visible)]="editgroup"
          [modal]="true" [draggable]="false" [resizable]="false" #editgroupdialog>
    <div>
      <div class="p-field">
        <label for="groupname">{{ 'GENERAL.GROUPNAME' | translate }} (*)</label>
        <input id="groupname" type="username" aria-describedby="groupname-help"
                pInputText style="width: 100%;"
                placeholder="{{ 'GENERAL.ENTERGROUPNAME' | translate }}"
                [(ngModel)]="GroupName" />
        <small id="groupname-help" class="p-error" *ngIf="groupnameerror">
          {{ 'GENERAL.ENTERGROUPNAME' | translate }}
        </small>
      </div>
      <br>
      <div class="p-field">
        <label for="groupdesc">{{ 'GENERAL.GROUPDESC' | translate }} (*)</label>
        <textarea rows="5" cols="30" pInputTextarea [autoResize]="true"
                  [style]="{'min-height': '125px', 'width': '100%'}" id="groupdesc" 
                  placeholder="{{ 'GENERAL.ENTERGROUPDESC' | translate }}"
                  [(ngModel)]="GroupDesc" >
        </textarea>
        <small id="groupdesc-help" class="p-error" *ngIf="groupdescerror">
          {{ 'GENERAL.ENTERGROUPDESC' | translate }}
        </small>
      </div>
    </div>
    <br>
    <div>
      <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
              style="margin-right: 10px;" class="p-button-raised p-button-secondary"
              (click)="cancel()"
              [disabled]="loading"
              id="cancelgroup">
      </button>
      <button pButton type="button" label="{{ 'GENERAL.SAVE' | translate }}"
              style="margin-top: 5px;" class="p-button-raised"
              (click)="saveGroup()"
              [disabled]="loading"
              id="savegroup">
      </button>
    </div>
</p-dialog>

<!-- ADD GROUP PERMISSION -->
<p-dialog header="{{ 'GENERAL.SELECTGROUPTOADD' | translate }}" [(visible)]="addperm"
          [modal]="true" [baseZIndex]="0" [focusOnShow]="false"
          [draggable]="false" [resizable]="false">
  <p *ngIf="!groups.length">{{ 'GENERAL.NOGROUPS' | translate }}</p>
  <div>
    <li *ngFor="let group of usergroups" style="list-style: none; padding: 5px;"
        id="{{group.groupName}}"
        (click)="addPermission(group)" class="pointer">
      {{ group.groupName }}
    </li>
  </div>
</p-dialog>

<!-- CREATE CONTACT -->
<p-dialog header="{{ 'GENERAL.CREATECONTACT' | translate }}" [(visible)]="addcontact"
          [modal]="true" [draggable]="false" [resizable]="false" #createcontactdialog
          [style]="{'min-width': '80%'}">
    <div style="float: left; width: 100%;" class="maininfo">
      <div style="float: left; padding-right: 5px; width: 30%;" class="contactinfo">
        <div class="p-field">
          <label for="contactgroup">{{ 'GENERAL.CONTACTGROUP' | translate }} (*)</label>
          <p-multiSelect [options]="contactgroups" [(ngModel)]="ContactGroup"
                         defaultLabel="{{ 'GENERAL.SELECTCONTACTGROUP' | translate }}" 
                         selectedItemsLabel="{0} {{ 'GENERAL.ITEMSSELECTED' | translate }}"
                         [style]="{width:'100%'}"
                         id="contactgroup">
          </p-multiSelect>
          <small id="contactgroup-help" class="p-error" *ngIf="contactgrouperror">
            {{ 'GENERAL.SELECTCONTACTGROUP' | translate }}
          </small>
        </div>
        <br>
        <div class="p-field">
          <label for="contacttype">{{ 'GENERAL.CONTACTTYPE' | translate }} (*)</label>
          <p-dropdown [options]="contacttypes" [(ngModel)]="ContactType"
                      placeholder="{{ 'GENERAL.SELECTCONTACTTYPE' | translate }}"
                      [style]="{width:'100%'}" [appendTo]="createcontactdialog"
                      id="contacttype">
            </p-dropdown>
          <small id="contacttype-help" class="p-error" *ngIf="contacttypeerror">
            {{ 'GENERAL.SELECTCONTACTTYPE' | translate }}
          </small>
        </div>
        <div *ngIf="ContactType !== undefined && ContactType !== null && ContactType == 'Person'">
          <br>
          <div class="p-field">
            <label for="firstname">{{ 'GENERAL.FIRSTNAME' | translate }} (*)</label>
            <input id="firstname" type="username" aria-describedby="firstname-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERFIRSTNAME' | translate }}"
                    [(ngModel)]="FirstName" />
            <small id="firstname-help" class="p-error" *ngIf="firstnameerror">
              {{ 'GENERAL.ENTERFIRSTNAME' | translate }}
            </small>
          </div>
          <br>
          <div class="p-field">
            <label for="lastname">{{ 'GENERAL.LASTNAME' | translate }} (*)</label>
            <input id="lastname" type="username" aria-describedby="lastname-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERLASTNAME' | translate }}"
                    [(ngModel)]="LastName" />
            <small id="lastname-help" class="p-error" *ngIf="lastnameerror">
              {{ 'GENERAL.ENTERLASTNAME' | translate }}
            </small>
          </div>
          <br>
          <div class="p-field">
            <label for="contacttitle">{{ 'GENERAL.CONTACTTITLE' | translate }} (*)</label>
            <input id="contacttitle" type="username" aria-describedby="contacttitle-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERCONTACTTITLE' | translate }}"
                    [(ngModel)]="ContactTitle" />
            <small id="contacttitle-help" class="p-error" *ngIf="contacttitleerror">
              {{ 'GENERAL.ENTERCONTACTTITLE' | translate }}
            </small>
          </div>
          <br>
          <div class="p-field">
            <label for="dob">{{ 'GENERAL.DATEOFBIRTH' | translate }} (*)</label>
            <div class="p-field p-col-12 p-md-4">
              <p-calendar [monthNavigator]="true" id="dob"
                          [yearNavigator]="true" yearRange="1901:3000"
                          [showIcon]="true" dateFormat="M d, yy"
                          placeholder="{{ 'GENERAL.SELECTDATEOFBIRTH' | translate }}"
                          [(ngModel)]="DoB" [appendTo]="createcontactdialog"
                          [style]="{'width': '100%'}">
              </p-calendar>
            </div>
            <small id="dob-help" class="p-error" *ngIf="doberror">
              {{ 'GENERAL.SELECTDATEOFBIRTH' | translate }}
            </small>
          </div>
          <br>
          <div class="p-field">
            <label for="company">{{ 'GENERAL.COMPANY' | translate }} (*)</label>
            <p-dropdown [options]="companies" [(ngModel)]="Company"
                        placeholder="{{ 'GENERAL.SELECTCOMPANY' | translate }}"
                        [style]="{width:'100%'}" [appendTo]="createcontactdialog"
                        id="company">
              </p-dropdown>
            <small id="company-help" class="p-error" *ngIf="companyerror">
              {{ 'GENERAL.SELECTCOMPANY' | translate }}
            </small>
          </div>
        </div>
        <div *ngIf="ContactType !== undefined && ContactType !== null && ContactType == 'Company'">
          <br>
          <div class="p-field">
            <label for="companyname">{{ 'GENERAL.COMPANYNAME' | translate }} (*)</label>
            <input id="companyname" type="username" aria-describedby="companyname-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERCOMPANYNAME' | translate }}"
                    [(ngModel)]="CompanyName" />
            <small id="companyname-help" class="p-error" *ngIf="companynameerror">
              {{ 'GENERAL.ENTERCOMPANYNAME' | translate }}
            </small>
          </div>
          <br>
          <div class="p-field">
            <label for="doc">{{ 'GENERAL.DATEOFCREATION' | translate }} (*)</label>
            <div class="p-field p-col-12 p-md-4">
              <p-calendar [monthNavigator]="true" id="doc"
                          [yearNavigator]="true" yearRange="1901:3000"
                          [showIcon]="true" dateFormat="M d, yy"
                          placeholder="{{ 'GENERAL.SELECTDATEOFCREATION' | translate }}"
                          [(ngModel)]="DoB" [appendTo]="createcontactdialog"
                          [style]="{'width': '100%'}">
              </p-calendar>
            </div>
            <small id="doc-help" class="p-error" *ngIf="docerror">
              {{ 'GENERAL.SELECTDATEOFCREATION' | translate }}
            </small>
          </div>
        </div>
        <li *ngFor="let item of singlescreate" style="list-style: none;">
          <br>
          <div class="p-field">
            <label for="{{item.Def.channelTypeName}}">{{ item.Def.channelTypeName }} (*)</label>
            <input id="{{item.Def.channelTypeName}}" type="username"
                   pInputText style="width: 100%;"
                   placeholder="{{ 'GENERAL.ENTER' | translate }} {{item.Def.channelTypeName}}"
                   [(ngModel)]="item.ChannelValue" />
            <small id="{{item.Def.channelTypeName}}-help" class="p-error" *ngIf="item.IsError">
              {{ 'GENERAL.ENTER' | translate }} {{item.Def.channelTypeName}}
            </small>
          </div>
        </li>
        <br>
        <div>
          <input type="file" hidden (change)="onFileSelected()" #fileInput id="file" accept="image/*">
          <div class="p-field">
            <label for="picture">{{ 'GENERAL.PICTURE' | translate }} (*)</label>
            <div>
              <button pButton type="button" (click)="fileInput.click()"
                      style="margin-top: 0px; margin-bottom: -10px; width: 100%; color: #4b4949;
                              padding-top: 7px; background-color: lightgray; border: none;"
                      class="p-button-raised" label="{{ 'GENERAL.SELECTPICTURE' | translate }}">
              </button>
            </div>
            <p>
              <img [src]="pic" style="width: 50px; height: 50px; margin-right: 15px; margin-left: 10px;
                                    margin-bottom: 5px;"
                  *ngIf="pic"/>
              <label *ngIf="picturename">{{ picturename }}</label>
            </p>
            <small id="picture-help" class="p-error" *ngIf="pictureerror">
              {{ 'GENERAL.SELECTPICTURE' | translate }}
            </small>
          </div>
        </div>
        <br>
      </div>
  
      <div style="float: right; margin-left: 30px; width: 65%;"
           class="channels">
        <div>
          <!-- CHANNELS -->
          <div *ngIf="multiplescreate.length > 0">
            <div *ngFor="let value of multiplescreate">
              <div style="float: left; width: 31%; margin-right: 5px;" class="items">
                <p-card [style]="{'height': '250px', 'width': '100%'}" styleClass="p-card-shadow">
                  <ng-template pTemplate="header">
                    <label style="padding: 5px; font-weight: bold;">{{ value.channelTypeName }}</label>
                  </ng-template>
                  <div style="margin: -10px; margin-top: -30px;">
                    <p-scrollPanel [style]="{'height': '185px', 'width': '100%'}" styleClass="custombar">
                      <li style="list-style: none;" *ngFor="let item of value.setValues"
                          class="hoveritem" (click)="setItem(item.ChannelValue)">
                        <div class="header">
                          <div class="toolbar" role="banner">
                            <label id="label{{ item.ChannelValue }}">{{ item.ChannelValue }}</label>
                            <div class="spacer2"></div>
                            <label style="margin-left: 5px;" (click)="changeFavoriteItemCreate(value, item)"
                                    class="pointer"
                                    id="fav{{value.channelTypeName}}{{item.ChannelValue}}">
                              <i class="pi pi-star" style="color: #f7f723; font-size: 1.5rem" 
                                *ngIf="item.FavoriteValue == 'Y'"></i>
                              <i class="pi pi-star" style="color: #757474; font-size: 1.5rem" 
                                  *ngIf="item.FavoriteValue == 'N'"></i>
                            </label>
                            <span id="Title">
                              <button class="focus-none"
                                      id="edititem{{value.channelTypeName}}{{item.ChannelValue}}"
                                      style="border: none; background-color: transparent;"
                                      (click)="itemmenu.toggle($event)">
                                <label style="height: 50px; text-align: center; margin-right: 0px;
                                              padding: 0px;" class="pointer">
                                  <i class="pi pi-ellipsis-h" style="font-size: 1.5em"></i>
                                </label>
                              </button>
                              <p-menu #itemmenu [popup]="true" [model]="value.menu"
                                      [appendTo]="createcontactdialog"></p-menu>
                            </span>
                          </div>
                        </div>
                      </li>
                    </p-scrollPanel>
                  </div>
                  <ng-template pTemplate="footer">
                    <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
                            style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                            margin-right: -10px; margin-top: -10px;" class="p-button-raised"
                            (click)="showAddItemCreate(value)"
                            id="createadd{{value.channelTypeName}}">
                    </button>
                    <!-- <p-button label="{{ 'GENERAL.ADD' | translate }}"
                              [style]="{'float': 'right', 'padding': '1px', 'padding-left': '5px',
                                        'padding-right': '5px', 'margin-right': '-10px',
                                        'margin-top': '-10px'}"
                              (click)="showAddItemCreate(value)">
                    </p-button> -->
                    <p>
                      <small id="{{ value.channelTypeName }}-help" class="p-error" *ngIf="value.iserror">
                        {{ 'GENERAL.SELECTATLEASTONE' | translate }} {{ value.channelTypeName }}
                      </small>
                    </p>
                </ng-template>
                </p-card>
              </div>
            </div>
          </div>
          <!-- LOCATIONS -->
          <div style="float: left; width: 31%;" class="locations">
            <p-card [style]="{'height': '250px', 'width': '100%'}" styleClass="p-card-shadow">
              <ng-template pTemplate="header">
                <label style="padding: 5px; font-weight: bold;">{{ 'GENERAL.LOCATION' | translate }}</label>
              </ng-template>
              <div style="margin: -10px; margin-top: -30px;">
                <p-scrollPanel [style]="{'height': '185px', 'width': '100%'}" styleClass="custombar">
                  <li style="list-style: none;" *ngFor="let item of locs" class="hoveritem">
                    <div class="header">
                      <div class="toolbar" role="banner">
                        <label id="label{{ item.Name }}">{{ item.Name }}</label>
                        <div class="spacer2"></div>
                        <label style="margin-left: 5px;" (click)="changeFavoriteLocCreate(item)"
                               class="pointer" id="favloc{{item.Name}}">
                          <i class="pi pi-star" style="color: #f7f723; font-size: 1.5rem" 
                            *ngIf="item.FavoriteValue == 'Y'"></i>
                          <i class="pi pi-star" style="color: #757474; font-size: 1.5rem" 
                              *ngIf="item.FavoriteValue == 'N'"></i>
                        </label>
                        <span id="Title">
                          <button class="focus-none"
                                  id="editloccreate{{item.Name}}"
                                  style="border: none; background-color: transparent;"
                                  (click)="itemmenuloc.toggle($event)">
                            <label style="height: 50px; text-align: center; margin-right: 0px;
                                          padding: 0px;" class="pointer">
                              <i class="pi pi-ellipsis-h" style="font-size: 1.5em"></i>
                            </label>
                          </button>
                          <p-menu #itemmenuloc [popup]="true" [model]="item.Menu"
                                  [appendTo]="createcontactdialog"></p-menu>
                        </span>
                      </div>
                    </div>
                  </li>
                </p-scrollPanel>
              </div>
              <ng-template pTemplate="footer">
                <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
                        style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                        margin-right: -10px; margin-top: -10px;" class="p-button-raised"
                        (click)="showAddLoc()"
                        id="createaddloc">
                </button>
                <!-- <p-button label="{{ 'GENERAL.ADD' | translate }}"
                          [style]="{'float': 'right', 'padding': '1px', 'padding-left': '5px',
                                    'padding-right': '5px', 'margin-right': '-10px',
                                    'margin-top': '-10px'}"
                          (click)="showAddLoc()">
                </p-button> -->
                <p>
                  <small id="location-help" class="p-error" *ngIf="locationerror">
                    {{ 'GENERAL.SELECTATLEASTONELOCATION' | translate }}
                  </small>
                </p>
            </ng-template>
            </p-card>
          </div>
        </div>
      </div>
    </div>

    <div>
      <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
              style="margin-right: 10px;" class="p-button-raised p-button-secondary"
              (click)="cancel()"
              [disabled]="loading"
              id="cancelgroup">
      </button>
      <button pButton type="button" label="{{ 'GENERAL.CREATE' | translate }}"
              style="margin-top: 5px;" class="p-button-raised"
              (click)="createContact()"
              [disabled]="loading"
              id="createcontact">
      </button>
    </div>
</p-dialog>

<!-- SHOW LOCATIONS -->
<p-dialog header="{{ 'GENERAL.SELECTLOCATIONTOADD' | translate }}" [(visible)]="showlocs"
          [modal]="true" [baseZIndex]="0" [focusOnShow]="false"
          [draggable]="false" [resizable]="false">
  <p *ngIf="!zones.length">{{ 'GENERAL.NOLOCATIONS' | translate }}</p>
  <div>
    <li *ngFor="let zone of zones" style="list-style: none; padding: 5px;"
        id="{{zone.zoneName}}"
        (click)="addLocCreate(zone)" class="hoveritem">
      {{ zone.zoneName }}
    </li>
  </div>
</p-dialog>

<!-- SHOW ADD CHANNEL ITEM -->
<p-dialog header="{{ 'GENERAL.ADD' | translate }} {{ value.channelTypeName }}" [(visible)]="additem"
          [modal]="true" [baseZIndex]="0" [focusOnShow]="false"
          [draggable]="false" [resizable]="false" [style]="{'min-width': '250px'}">
  <div>
    <div class="p-field">
      <label for="value">{{ value.channelTypeName }} (*)</label>
      <input id="value" type="username" aria-describedby="value-help"
              pInputText style="width: 100%;"
              placeholder="{{ 'GENERAL.ENTER' | translate }} {{ value.channelTypeName }}"
              [(ngModel)]="ItemValue" />
      <small id="value-help" class="p-error" *ngIf="valueerror">
        {{ 'GENERAL.ENTER' | translate }} {{ value.channelTypeName }}
      </small>
    </div>
    <br>
    <div>
      <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
              style="margin-right: 10px;" class="p-button-raised p-button-secondary"
              (click)="cancelItem()"
              [disabled]="loading">
      </button>
      <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
              style="margin-top: 5px;" class="p-button-raised"
              (click)="addItemCreate()"
              [disabled]="loading"
              id="additem">
      </button>
    </div>
  </div>
</p-dialog>

<!-- SHOW EDIT CHANNEL ITEM -->
<p-dialog header="{{ 'GENERAL.EDIT' | translate }} {{ value.channelTypeName }}" [(visible)]="edititem"
          [modal]="true" [baseZIndex]="0" [focusOnShow]="false"
          [draggable]="false" [resizable]="false" [style]="{'min-width': '250px'}">
  <div>
    <div class="p-field">
      <label for="value">{{ value.channelTypeName }} (*)</label>
      <input id="value" type="username" aria-describedby="value-help"
              pInputText style="width: 100%;"
              placeholder="{{ 'GENERAL.ENTER' | translate }} {{ value.channelTypeName }}"
              [(ngModel)]="ItemValue" />
      <small id="value-help" class="p-error" *ngIf="valueerror">
        {{ 'GENERAL.ENTER' | translate }} {{ value.channelTypeName }}
      </small>
    </div>
    <br>
    <div>
      <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
              style="margin-right: 10px;" class="p-button-raised p-button-secondary"
              (click)="cancelItem()"
              [disabled]="loading">
      </button>
      <button pButton type="button" label="{{ 'GENERAL.SAVE' | translate }}"
              style="margin-top: 5px;" class="p-button-raised"
              (click)="editItemCreate()"
              [disabled]="loading"
              id="saveitem">
      </button>
    </div>
  </div>
</p-dialog>

<!-- EDIT CONTACT -->
<p-dialog header="{{ 'GENERAL.EDITCONTACT' | translate }}" [(visible)]="editcontact"
          [modal]="true" [draggable]="false" [resizable]="false" #createcontactdialog
          [style]="{'min-width': '80%'}">
    <div style="float: left;; width: 100%;" class="maininfo">
      <div style="float: left; padding-right: 5px; width: 30%;" class="contactinfo">
        <div class="p-field">
          <label for="contactgroup">{{ 'GENERAL.CONTACTGROUP' | translate }} (*)</label>
          <p-multiSelect [options]="contactgroups" [(ngModel)]="ContactGroup"
                         defaultLabel="{{ 'GENERAL.SELECTCONTACTGROUP' | translate }}" 
                         selectedItemsLabel="{0} {{ 'GENERAL.ITEMSSELECTED' | translate }}"
                         [style]="{width:'100%'}">
          </p-multiSelect>
          <small id="contactgroup-help" class="p-error" *ngIf="contactgrouperror">
            {{ 'GENERAL.SELECTCONTACTGROUP' | translate }}
          </small>
        </div>
        <br>
        <div class="p-field">
          <label for="contacttype">{{ 'GENERAL.CONTACTTYPE' | translate }} (*)</label>
          <p-dropdown [options]="contacttypes" [(ngModel)]="ContactType"
                      placeholder="{{ 'GENERAL.SELECTCONTACTTYPE' | translate }}"
                      [style]="{width:'100%'}" [appendTo]="createcontactdialog"
                      id="contacttype" [disabled]="true">
            </p-dropdown>
          <small id="contacttype-help" class="p-error" *ngIf="contacttypeerror">
            {{ 'GENERAL.SELECTCONTACTTYPE' | translate }}
          </small>
        </div>
        <div *ngIf="ContactType !== undefined && ContactType !== null && ContactType == 'Person'">
          <br>
          <div class="p-field">
            <label for="firstname">{{ 'GENERAL.FIRSTNAME' | translate }} (*)</label>
            <input id="firstname" type="username" aria-describedby="firstname-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERFIRSTNAME' | translate }}"
                    [(ngModel)]="FirstName" />
            <small id="firstname-help" class="p-error" *ngIf="firstnameerror">
              {{ 'GENERAL.ENTERFIRSTNAME' | translate }}
            </small>
          </div>
          <br>
          <div class="p-field">
            <label for="lastname">{{ 'GENERAL.LASTNAME' | translate }} (*)</label>
            <input id="lastname" type="username" aria-describedby="lastname-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERLASTNAME' | translate }}"
                    [(ngModel)]="LastName" />
            <small id="lastname-help" class="p-error" *ngIf="lastnameerror">
              {{ 'GENERAL.ENTERLASTNAME' | translate }}
            </small>
          </div>
          <br>
          <div class="p-field">
            <label for="contacttitle">{{ 'GENERAL.CONTACTTITLE' | translate }} (*)</label>
            <input id="contacttitle" type="username" aria-describedby="contacttitle-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERCONTACTTITLE' | translate }}"
                    [(ngModel)]="ContactTitle" />
            <small id="contacttitle-help" class="p-error" *ngIf="contacttitleerror">
              {{ 'GENERAL.ENTERCONTACTTITLE' | translate }}
            </small>
          </div>
          <br>
          <div class="p-field">
            <label for="dob">{{ 'GENERAL.DATEOFBIRTH' | translate }} (*)</label>
            <div class="p-field p-col-12 p-md-4">
              <p-calendar [monthNavigator]="true" id="dob"
                          [yearNavigator]="true" yearRange="1901:3000"
                          [showIcon]="true" dateFormat="M d, yy"
                          placeholder="{{ 'GENERAL.SELECTDATEOFBIRTH' | translate }}"
                          [(ngModel)]="DoB" [appendTo]="createcontactdialog"
                          [style]="{'width': '100%'}">
              </p-calendar>
            </div>
            <small id="dob-help" class="p-error" *ngIf="doberror">
              {{ 'GENERAL.SELECTDATEOFBIRTH' | translate }}
            </small>
          </div>
          <br>
          <div class="p-field">
            <label for="company">{{ 'GENERAL.COMPANY' | translate }} (*)</label>
            <p-dropdown [options]="companies" [(ngModel)]="Company"
                        placeholder="{{ 'GENERAL.SELECTCOMPANY' | translate }}"
                        [style]="{width:'100%'}" [appendTo]="createcontactdialog"
                        id="company">
              </p-dropdown>
            <small id="company-help" class="p-error" *ngIf="companyerror">
              {{ 'GENERAL.SELECTCOMPANY' | translate }}
            </small>
          </div>
        </div>
        <div *ngIf="ContactType !== undefined && ContactType !== null && ContactType == 'Company'">
          <br>
          <div class="p-field">
            <label for="companyname">{{ 'GENERAL.COMPANYNAME' | translate }} (*)</label>
            <input id="companyname" type="username" aria-describedby="companyname-help"
                    pInputText style="width: 100%;"
                    placeholder="{{ 'GENERAL.ENTERCOMPANYNAME' | translate }}"
                    [(ngModel)]="CompanyName" />
            <small id="companyname-help" class="p-error" *ngIf="companynameerror">
              {{ 'GENERAL.ENTERCOMPANYNAME' | translate }}
            </small>
          </div>
          <br>
          <div class="p-field">
            <label for="doc">{{ 'GENERAL.DATEOFCREATION' | translate }} (*)</label>
            <div class="p-field p-col-12 p-md-4">
              <p-calendar [monthNavigator]="true" id="doc"
                          [yearNavigator]="true" yearRange="1901:3000"
                          [showIcon]="true" dateFormat="M d, yy"
                          placeholder="{{ 'GENERAL.SELECTDATEOFCREATION' | translate }}"
                          [(ngModel)]="DoB" [appendTo]="createcontactdialog"
                          [style]="{'width': '100%'}">
              </p-calendar>
            </div>
            <small id="doc-help" class="p-error" *ngIf="docerror">
              {{ 'GENERAL.SELECTDATEOFCREATION' | translate }}
            </small>
          </div>
        </div>
        <li *ngFor="let item of singlesedit" style="list-style: none;">
          <br>
          <div class="p-field">
            <label for="{{item.ChannelDef.ChannelTypeName}}">{{ item.ChannelDef.ChannelTypeName }} (*)</label>
            <input id="{{item.ChannelDef.ChannelTypeName}}" type="username"
                   pInputText style="width: 100%;"
                   placeholder="{{ 'GENERAL.ENTER' | translate }} {{item.ChannelDef.ChannelTypeName}}"
                   [(ngModel)]="item.Channel.ChannelValue" />
            <small id="{{item.ChannelDef.ChannelTypeName}}-help" class="p-error" *ngIf="item.IsError">
              {{ 'GENERAL.ENTER' | translate }} {{item.ChannelDef.ChannelTypeName}}
            </small>
          </div>
        </li>
        <br>
        <div>
          <input type="file" hidden (change)="onFileSelectedEdit()" #fileInput2 id="file2" accept="image/*">
          <div class="p-field">
            <label for="picture">{{ 'GENERAL.PICTURE' | translate }} (*)</label>
            <div>
              <button pButton type="button" (click)="fileInput2.click()"
                      style="margin-top: 0px; margin-bottom: -10px; width: 100%; color: #4b4949;
                              padding-top: 7px; background-color: lightgray; border: none;"
                      class="p-button-raised" label="{{ 'GENERAL.SELECTPICTURE' | translate }}">
              </button>
            </div>
            <p>
              <img [src]="pic" style="width: 50px; height: 50px; margin-right: 15px; margin-left: 10px;
                                    margin-bottom: 5px;"
                  *ngIf="pic"/>
              <label *ngIf="picturename">{{ picturename }}</label>
            </p>
            <small id="picture-help" class="p-error" *ngIf="pictureerror">
              {{ 'GENERAL.SELECTPICTURE' | translate }}
            </small>
          </div>
        </div>
        <br>
      </div>
  
      <div style="float: right; margin-left: 30px; width: 65%;"
           class="channels">
        <div>
          <!-- CHANNELS -->
          <div *ngIf="multiplesedit.length > 0">
            <div *ngFor="let value of multiplesedit">
              <div style="float: left; width: 31%; margin-right: 5px; margin-bottom: 5px;" class="items">
                <p-card [style]="{'height': '250px', 'width': '100%'}" styleClass="p-card-shadow">
                  <ng-template pTemplate="header">
                    <label style="padding: 5px; font-weight: bold;">{{ value[0] }}</label>
                  </ng-template>
                  <div style="margin: -10px; margin-top: -30px;">
                    <p-scrollPanel [style]="{'height': '185px', 'width': '100%'}" styleClass="custombar">
                      <li style="list-style: none;" *ngFor="let item of value[1]"
                          class="hoveritem" (click)="setItemEdit(item)">
                        <div class="header">
                          <div class="toolbar" role="banner">
                            <label id="label{{ item.Channel.ChannelValue }}">{{ item.Channel.ChannelValue }}</label>
                            <div class="spacer2"></div>
                            <label style="margin-left: 5px;" (click)="changeFavoriteItemEdit(value, item)"
                                   id="fav{{item.ChannelDef.ChannelTypeName}}{{item.Channel.ChannelValue}}" class="pointer">
                              <i class="pi pi-star" style="color: #f7f723; font-size: 1.5rem" 
                                *ngIf="item.Channel.FavoriteValue == 'Y'"></i>
                              <i class="pi pi-star" style="color: #757474; font-size: 1.5rem" 
                                  *ngIf="item.Channel.FavoriteValue == 'N'"></i>
                            </label>
                            <span id="Title">
                              <button class="focus-none"
                                      id="edititem{{item.ChannelDef.ChannelTypeName}}{{item.Channel.ChannelValue}}"
                                      style="border: none; background-color: transparent;"
                                      (click)="itemmenu.toggle($event)">
                                <label style="height: 50px; text-align: center; margin-right: 0px;
                                              padding: 0px;" class="pointer">
                                  <i class="pi pi-ellipsis-h" style="font-size: 1.5em"></i>
                                </label>
                              </button>
                              <p-menu #itemmenu [popup]="true" [model]="value.menu"
                                      [appendTo]="createcontactdialog"></p-menu>
                            </span>
                          </div>
                        </div>
                      </li>
                    </p-scrollPanel>
                  </div>
                  <ng-template pTemplate="footer">
                    <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
                            style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                            margin-right: -10px; margin-top: -10px;" class="p-button-raised"
                            (click)="showAddItemEdit(value)"
                            id="editadd{{value[0]}}">
                    </button>
                    <!-- <p-button label="{{ 'GENERAL.ADD' | translate }}"
                              [style]="{'float': 'right', 'padding': '1px', 'padding-left': '5px',
                                        'padding-right': '5px', 'margin-right': '-10px',
                                        'margin-top': '-10px'}"
                              (click)="showAddItemEdit(value)">
                    </p-button> -->
                    <p>
                      <small id="{{ value.channelTypeName }}-help" class="p-error" *ngIf="value.iserror">
                        {{ 'GENERAL.SELECTATLEASTONE' | translate }} {{ value.channelTypeName }}
                      </small>
                    </p>
                </ng-template>
                </p-card>
              </div>
            </div>
          </div>
          <!-- LOCATIONS -->
          <div style="float: left; width: 31%;" class="locations">
            <p-card [style]="{'height': '250px', 'width': '100%'}" styleClass="p-card-shadow">
              <ng-template pTemplate="header">
                <label style="padding: 5px; font-weight: bold;">{{ 'GENERAL.LOCATION' | translate }}</label>
              </ng-template>
              <div style="margin: -10px; margin-top: -30px;">
                <p-scrollPanel [style]="{'height': '185px', 'width': '100%'}" styleClass="custombar">
                  <li style="list-style: none;" *ngFor="let item of locs" class="hoveritem">
                    <div class="header">
                      <div class="toolbar" role="banner">
                        <label id="label{{ item.Name }}">{{ item.Name }}</label>
                        <div class="spacer2"></div>
                        <label style="margin-left: 5px;" (click)="changeFavoriteLocEdit(item)"
                               class="pointer" id="favloc{{item.Name}}">
                          <i class="pi pi-star" style="color: #f7f723; font-size: 1.5rem" 
                            *ngIf="item.FavoriteValue == 'Y'"></i>
                          <i class="pi pi-star" style="color: #757474; font-size: 1.5rem" 
                              *ngIf="item.FavoriteValue == 'N'"></i>
                        </label>
                        <span id="Title">
                          <button class="focus-none"
                                  id="editlocedit{{item.Name}}"
                                  style="border: none; background-color: transparent;"
                                  (click)="itemmenuloc.toggle($event)">
                            <label style="height: 50px; text-align: center; margin-right: 0px;
                                          padding: 0px;" class="pointer">
                              <i class="pi pi-ellipsis-h" style="font-size: 1.5em"></i>
                            </label>
                          </button>
                          <p-menu #itemmenuloc [popup]="true" [model]="item.Menu"
                                  [appendTo]="createcontactdialog"></p-menu>
                        </span>
                      </div>
                    </div>
                  </li>
                </p-scrollPanel>
              </div>
              <ng-template pTemplate="footer">
                <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
                        style="float: right; padding: 1px; padding-left: 5px; padding-right: 5px;
                        margin-right: -10px; margin-top: -10px;" class="p-button-raised"
                        (click)="showAddLoc()"
                        id="editaddloc">
                </button>
                <!-- <p-button label="{{ 'GENERAL.ADD' | translate }}"
                          [style]="{'float': 'right', 'padding': '1px', 'padding-left': '5px',
                                    'padding-right': '5px', 'margin-right': '-10px',
                                    'margin-top': '-10px'}"
                          (click)="showAddLoc()">
                </p-button> -->
                <p>
                  <small id="location-help" class="p-error" *ngIf="locationerror">
                    {{ 'GENERAL.SELECTATLEASTONELOCATION' | translate }}
                  </small>
                </p>
            </ng-template>
            </p-card>
          </div>
        </div>
      </div>
    </div>

    <div>
      <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
              style="margin-right: 10px;" class="p-button-raised p-button-secondary"
              (click)="cancel()"
              [disabled]="loading"
              id="cancelgroup">
      </button>
      <button pButton type="button" label="{{ 'GENERAL.SAVE' | translate }}"
              style="margin-top: 5px;" class="p-button-raised"
              (click)="saveContact()"
              [disabled]="loading"
              id="savecontact">
      </button>
    </div>
</p-dialog>

<!-- SHOW ADD CHANNEL ITEM EDIT -->
<p-dialog header="{{ 'GENERAL.ADD' | translate }} {{ addtitle }}" [(visible)]="additemedit"
          [modal]="true" [baseZIndex]="0" [focusOnShow]="false"
          [draggable]="false" [resizable]="false" [style]="{'min-width': '250px'}">
  <div>
    <div class="p-field">
      <label for="value">{{ addtitle }} (*)</label>
      <input id="value" type="username" aria-describedby="value-help"
              pInputText style="width: 100%;"
              placeholder="{{ 'GENERAL.ENTER' | translate }} {{ addtitle }}"
              [(ngModel)]="ItemValue" />
      <small id="value-help" class="p-error" *ngIf="valueerror">
        {{ 'GENERAL.ENTER' | translate }} {{ addtitle }}
      </small>
    </div>
    <br>
    <div>
      <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
              style="margin-right: 10px;" class="p-button-raised p-button-secondary"
              (click)="cancelItem()"
              [disabled]="loading">
      </button>
      <button pButton type="button" label="{{ 'GENERAL.ADD' | translate }}"
              style="margin-top: 5px;" class="p-button-raised"
              (click)="addItemEdit()"
              [disabled]="loading"
              id="additem">
      </button>
    </div>
  </div>
</p-dialog>

<!-- SHOW EDIT CHANNEL ITEM EDIT -->
<p-dialog header="{{ 'GENERAL.EDIT' | translate }} {{ addtitle }}" [(visible)]="edititemedit"
          [modal]="true" [baseZIndex]="0" [focusOnShow]="false"
          [draggable]="false" [resizable]="false" [style]="{'min-width': '250px'}">
  <div>
    <div class="p-field">
      <label for="value">{{ addtitle }} (*)</label>
      <input id="value" type="username" aria-describedby="value-help"
              pInputText style="width: 100%;"
              placeholder="{{ 'GENERAL.ENTER' | translate }} {{ addtitle }}"
              [(ngModel)]="ItemValue" />
      <small id="value-help" class="p-error" *ngIf="valueerror">
        {{ 'GENERAL.ENTER' | translate }} {{ addtitle }}
      </small>
    </div>
    <br>
    <div>
      <button pButton type="button" label="{{ 'GENERAL.CANCEL' | translate }}"
              style="margin-right: 10px;" class="p-button-raised p-button-secondary"
              (click)="cancelItem()"
              [disabled]="loading">
      </button>
      <button pButton type="button" label="{{ 'GENERAL.SAVE' | translate }}"
              style="margin-top: 5px;" class="p-button-raised"
              (click)="saveItemEdit()"
              [disabled]="loading"
              id="saveitem">
      </button>
    </div>
  </div>
</p-dialog>

<!-- CONFIRM DIALOG -->
<p-confirmDialog id="confirm" #cd header="{{ 'GENERAL.INFO' | translate }}"
                 [style]="{'min-width': '250px'}">
  <p-footer>
    <div *ngIf="isdelete">
      <button pButton type="button" label="{{ 'GENERAL.NO' | translate }}" (click)="cd.reject()"
              class="p-button-raised p-button-secondary" id="no"></button>
      <button type="button" pButton label="{{ 'GENERAL.YES' | translate }}"
              (click)="cd.accept()" id="yes"></button>
    </div>
  </p-footer>
</p-confirmDialog>

<!-- CONFIRM DIALOG 2 -->
<p-confirmDialog id="confirm2" #cd2 key="2" header="{{ 'GENERAL.INFO' | translate }}">
  <p-footer>
    <div>
      <button type="button" pButton label="{{ 'GENERAL.CLOSE' | translate }}"
              (click)="cd2.accept()" id="buttonclose2"></button>
   </div>
  </p-footer>
</p-confirmDialog>

<!-- PROGRESS SPINNER -->
<p-dialog [(visible)]="loading" [modal]="true" [showHeader]="false"
          [closable]="false" [autoZIndex]="true" id="loader">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
</p-dialog>