import { ContactGroupDef } from './contact-group-def';
import { ContactChannel } from './contact-channel';
import { ContactLocation } from './contact-location';
export class Contact {
    public ContactId!: number;
    public ContactType!: string;
    public Title!: string;
    public FirstName!: string;
    public LastName!: string;
    public DoB!: Date;
    public Picture!: string;
    public Company!: number;
    public Name!: string;
}

export class ContactInfo {
    public Contact: Contact = new Contact();
    public Locations: ContactLocation[] = [];
    public Channels: ContactChannel[] = [];
    public Group: ContactGroupDef[] = [];
    public CompanyName!: string;
    public Groups!: string;
}

export class NewContactInfo {
    public Contact: Contact = new Contact();
    public Locations: ContactLocation[] = [];
    public Channels: ContactChannel[] = [];
    public Group: any[] = [];
    public CompanyName!: string;
    public Groups!: string;
    public Color!: string;
    public Image!: any;
    public DateofBirth!: any;
}
