import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Helpers/variables';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  usmApiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.usmApiUrl = environment.usmApiUrl;
  }

  getGroups(page: number, size: number): any {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/groups/' + orgId + '/' + page + '/' + size;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUserGroups(gid: number, uid: number): any {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/usergroups/Get_ByUserId/' + orgId + '/' + gid + '/' + uid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getGroupById(id: number): any {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    const orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/groups/' + orgId + '/' + id;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
