export class ContactGroupDef {
        public GroupId: number | undefined;
        public GroupName: string | undefined;
        public GroupDesc: string | undefined;
        public GroupPerm: string | undefined;
}

export class ContactGroupDefInfo {
    public GroupId: number | undefined;
    public GroupName: string | undefined;
    public GroupDesc: string | undefined;
    public GroupPerm: string | undefined;
    public Color: string | undefined;
    public Icon: string | undefined;
    public ShowContacts = false;
}
