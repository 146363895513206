import { GroupPerm } from './../../Models/group-perm';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/Helpers/key-value';
import { Variables } from 'src/app/Helpers/variables';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class GroupPermService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getGroupPerms(groupid: number, perm: string): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();

    const url = this.apiUrl + '/api/groupperm/' + groupid + '/' + perm + '/' + gps + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postGroupPerm(perm: GroupPerm): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();

    perm.Groups = gps;
    return this.httpclient.post(this.apiUrl + '/api/groupperm', perm)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteGroupPerm(perm: GroupPerm): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();

    perm.Groups = gps;
    return this.httpclient.put(this.apiUrl + '/api/groupperm/delete/' + perm.GroupId, perm)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
