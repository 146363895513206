
<div style="height: 100%; padding: 10px;">
    
    <p-toast position="top-center" key="tc"></p-toast>
        
    <div style="height: 100%;">
        <router-outlet>
        </router-outlet>
    </div>

    <!-- PROGRESS SPINNER -->
    <p-dialog [(visible)]="loading" [modal]="true" [showHeader]="false"
                [closable]="false" [autoZIndex]="true">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}"
                             styleClass="custom-spinner"></p-progressSpinner>
        <!-- <p-progressSpinner [style]="{width: '50px', height: '50px'}"
                           styleClass="custom-spinner"></p-progressSpinner> -->
    </p-dialog>

    <!-- CONFIRM DIALOG -->
    <p-confirmDialog id="confirm" #cd key="load" header="{{ 'GENERAL.INFO' | translate }}">
        <p-footer>
            <div>
                <button type="button" pButton label="{{ 'GENERAL.CLOSE' | translate }}"
                        (click)="cd.accept()" id="buttonclose"></button>
            </div>
        </p-footer>
    </p-confirmDialog>
    
</div>