import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Helpers/variables';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
    this.apiUrl = environment.usmApiUrl;
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    this.orgId = +oi;
  }

  getUserPermissions(uid: number, gid: number, perm: string): any {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/userpermission/' + this.orgId + '/' + uid + '/' + gid + '/' + perm + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any): any {
    // console.error('error', error);
    throw error;
    return error;
  }

}
