import { ContactLocation } from './../../Models/contact-location';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/Helpers/key-value';
import { Variables } from 'src/app/Helpers/variables';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ContactLocationService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getContactLocations(page: number, size: number, contactid: number, locid: number, groupid: number): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();

    const url = this.apiUrl + '/api/contactlocation/' + page + '/' + size + '/' + contactid
     + '/' + locid + '/' + groupid + '/' + gps + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postLocation(loc: ContactLocation): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    loc.Groups = gps;
    return this.httpclient.post(this.apiUrl + '/api/contactlocation', loc)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putLocation(cid: number, value: Int64Updater): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    value.AdditionalValues2 = gps;
    return this.httpclient.put(this.apiUrl + '/api/contactlocation/' + cid, value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteLocation(cid: number, value: Int64Updater): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    value.AdditionalValues2 = gps;
    return this.httpclient.put(this.apiUrl + '/api/contactlocation/delete/' + cid, value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
