import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  usmApiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.usmApiUrl = environment.usmApiUrl;
  }

  getUserInfo(uid: number, empid: number, email: string, phone: string): any {
    // tslint:disable-next-line: max-line-length
    const url = this.usmApiUrl + '/api/users/ ' + uid + '/' + empid + '/' + email + '/' + phone + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
