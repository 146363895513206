import { ContactChannel } from './../../Models/contact-channel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Int64Updater } from 'src/app/Helpers/key-value';
import { Variables } from 'src/app/Helpers/variables';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { RangeValueAccessor } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ContactChannelService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  getContactChannels(page: number, size: number, contactid: number, groupid: number): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();

    const url = this.apiUrl + '/api/contactchannel/' + page + '/' + size + '/' + contactid
     + '/' + groupid + '/' + gps + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postContactChannel(chan: ContactChannel): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    chan.Groups = gps;
    return this.httpclient.post(this.apiUrl + '/api/contactchannel', chan)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putContactChannel(cid: number, value: Int64Updater[]): Observable<any> {
    return this.httpclient.put(this.apiUrl + '/api/contactchannel/' + cid, value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteContactChannel(cid: number, value: Int64Updater): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    value.AdditionalValues2 = gps;
    return this.httpclient.put(this.apiUrl + '/api/contactchannel/delete/' + cid, value)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
