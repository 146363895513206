import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Variables } from 'src/app/Helpers/variables';
import { ContactGroupData } from 'src/app/Models/contact-group';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ContactGroupService {

  apiUrl: string;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = environment.apiUrl;
  }

  postContactGroup(cg: ContactGroupData): Observable<any> {
    const v = new Variables();
    let val: string | null;
    val = localStorage.getItem('mg');
    const gps = CryptoJS.AES.decrypt(val as string, v.pass).toString(CryptoJS.enc.Utf8).toString();
    cg.Groups = gps;
    return this.httpclient.post(this.apiUrl + '/api/contactgroup', cg)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any): any {
    throw error;
    return error;
  }

}
